import React, { useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Button, Box, Typography, TextField, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'linear-gradient(50.08deg, #002F7A -16.9%, #254FE0 -16.89%, #698AFF 87.11%)',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  margin: theme.spacing(0.5),
}));

export default function InvitePeople() {
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showHelperText, setShowHelperText] = useState(false);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      if (validateEmail(inputValue) && !emails.includes(inputValue)) {
        setEmails([...emails, inputValue]);
        setInputValue('');
        setShowHelperText(false);
      } else {
        setShowHelperText(true);
      }
    }
  };

  const handleDelete = (emailToDelete) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '32px',
          fontWeight: 500,
          color: 'rgba(2, 17, 72, 0.9)',
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 1,
          mb: '40px',
        }}
      >
        Invite people to your Organization
      </Typography>
      <style>
        {`
          .custom-scrollbar {
            max-height: 200px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: rgba(105, 138, 255, 1) #e4efef;
          }

          .custom-scrollbar::-webkit-scrollbar {
            width: 5px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background: #e4efef;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: rgba(105, 138, 255, 1);
            border-radius: 2px;
          }
        `}
      </style>

      <TextField
        id="outlined-basic"
        label="Enter email"
        variant="outlined"
        helperText={
          showHelperText ? "Please enter a valid email address that hasn't been added yet." : ''
        }
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        sx={{ width: { md: '90%', lg: '65%', xs: '90%' } }}
      />
      <Box
        className="custom-scrollbar"
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          mb: 2,
          maxHeight: '200px',
          overflowY: 'auto',
          mt: 1,
        }}
      >
        {emails.map((email) => (
          <StyledChip
            key={email}
            label={email}
            onDelete={() => handleDelete(email)}
            deleteIcon={<CloseIcon />}
          />
        ))}
      </Box>

      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 4,
        }}
      >
        <BorderLinearProgress variant="determinate" value={87.5} />
      </Box>
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          display: 'flex',
          justifyContent: 'space-between',
          mt: 5,
          mb: 2,
        }}
      >
        {/* <Button variant="outlined" startIcon={<ArrowBackIosIcon />}>
          Back
        </Button> */}
        {emails.length < 1 ? (
          <Button
            variant="contained"
            onClick={() => {
              localStorage.removeItem('onboardingStep');
            }}
          >
            Skip Invite & Finish
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              localStorage.removeItem('onboardingStep');
            }}
          >
            Invite & Finish
          </Button>
        )}
      </Box>
    </>
  );
}
