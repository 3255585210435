import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ASSET_CONFIGS } from '../../assets/assetConfigs';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ICON from '../../assets/LandingPage/menu-icon.svg';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import { Badge } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import NotificationsPopup from '../../components/NotificationsPopup';
import navbar_logo from '../../assets/Logo/navbar_logo.svg';
import './Navbar.css';

const pages = [
  {
    id: 1,
    title: 'Home',
    link: 'home',
  },

  {
    id: 2,
    title: 'Products',
    link: 'products',
  },
  {
    id: 3,
    title: 'About Us',
    link: 'about',
  },
  {
    id: 4,
    title: 'Pricing',
    link: 'pricing',
  },
  {
    id: 5,
    title: 'Careers',
    link: '/careers',
  },
  {
    id: 6,
    title: 'Contact Us',
    link: '/contact-support',
  },
];

function Navbar({ scrollToSection, featuresRef, whyChooseUsRef, pricingRef, homeRef }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [userNotificationLength, setUserNotificationLength] = useState(0);
  const [inviteNotificationNumber, setInviteNotificationNumber] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [currentSection, setCurrentSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      if (location.pathname === '/') {
        if (pricingRef.current && scrollPosition >= pricingRef.current.offsetTop) {
          setCurrentSection('pricing');
        } else if (whyChooseUsRef.current && scrollPosition >= whyChooseUsRef.current.offsetTop) {
          setCurrentSection('about');
        } else if (featuresRef.current && scrollPosition >= featuresRef.current.offsetTop) {
          setCurrentSection('products');
        } else if (featuresRef.current && scrollPosition >= homeRef.current.offsetTop) {
          setCurrentSection('home');
        } else {
          setCurrentSection('home');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname, whyChooseUsRef, featuresRef, pricingRef, homeRef]);

  const isActive = (link) => {
    if (location.pathname === '/') {
      if (link === 'home') return currentSection === 'home';
      if (link === 'about') return currentSection === 'about';
      if (link === 'products') return currentSection === 'products';
      if (link === 'pricing') return currentSection === 'pricing';
    } else {
      return location.pathname === link;
    }
    return false;
  };

  const handleNavClick = (link) => {
    handleCloseNavMenu();

    if (link === 'home') {
      navigate('/');
      setCurrentSection('home');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (['about', 'products', 'pricing'].includes(link)) {
      if (location.pathname !== '/') {
        navigate('/', { state: { scrollTo: link } });
      } else {
        scrollToSection(
          link === 'about' ? whyChooseUsRef : link === 'products' ? featuresRef : pricingRef
        );
      }
      setCurrentSection(link);
    } else if (link === '/contact-support' || link === '/careers') {
      navigate(link);
      setCurrentSection(link.slice(1));
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    } else {
      navigate(link);
      setCurrentSection('');
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await organizationAPI.getNotifications();
      setUserNotificationLength(response.length);
    } catch (error) {}
  };

  const fetchInvite = async () => {
    try {
      const response = await organizationAPI.getInvitesRecievedByUser();
      setInviteNotificationNumber(response.length);
    } catch (error) {}
  };

  const closeNotificationsPopup = () => {
    setNotificationsPopup({
      open: false,
      anchorEl: null,
      placement: '',
    });
  };

  useEffect(() => {
    setNotificationCount(userNotificationLength + inviteNotificationNumber);
  }, [userNotificationLength, inviteNotificationNumber]);

  const [isMobileView, setIsMobileView] = useState(false);

  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    setIsMobileView(isMobile);
  }, [isMobile]);

  const [notificationsPopup, setNotificationsPopup] = useState({
    open: false,
    anchorEl: null,
    placement: '',
  });

  const toggleNotificationsPopup = (event) => {
    fetchNotifications();
    fetchInvite();
    setNotificationsPopup((prev) => ({
      open: !prev.open,
      anchorEl: prev.open ? null : event.currentTarget,
      placement: isMobileView ? 'bottom' : 'right',
    }));
  };

  const handleLogout = () => {
    localStorage.removeItem('Token');
    localStorage.clear();
    navigate('/sign-in');
  };

  const isLoggedIn = !!localStorage.getItem('Token');

  useEffect(() => {
    if (isLoggedIn) {
      fetchInvite();
      fetchNotifications();
    }
  }, [isLoggedIn]);

  const imagesObj = JSON.parse(localStorage.getItem('imagesObj'));
  let contact_logo_navbar_url = imagesObj && imagesObj[ASSET_CONFIGS.CONTEST_LOGO_NAVBAR];
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <div className="main-container-nav">
        <div className="nav-container">
          <div className="logo-wraper">
            <Link to="/organization">
              {contact_logo_navbar_url ? (
                <img src={contact_logo_navbar_url} alt="Logo" />
              ) : (
                <img src={navbar_logo} alt="Logo" />
              )}
            </Link>
          </div>
          <div className="nav-content">
            <div className={`Nav-menu-wraper ${isMobileView && 'a'}`}>
              {pages.map((page) => (
                <NavLink
                  key={page.link}
                  to={page.link}
                  className={isActive(page.link) ? 'active' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavClick(page.link);
                  }}
                >
                  {page.title}
                </NavLink>
              ))}
              <div className="buttons-wraper">
                {!isLoggedIn ? (
                  <button
                    className="secondary-btn is-responsive"
                    onClick={() => navigate('/sign-in')}
                  >
                    Sign In
                  </button>
                ) : (
                  <>
                    {console.log(window.location.href.includes('/organization'))}
                    {!window.location.href.includes('organization') && (
                      <button
                        className="secondary-btn is-responsive"
                        onClick={() => navigate('/organization')}
                      >
                        Dashboard
                      </button>
                    )}
                    <button className="secondary-btn is-responsive" onClick={handleLogout}>
                      Logout
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}
          >
            <div className="buttons-wraper">
              {!isLoggedIn ? (
                <button
                  className="secondary-btn is-desktop-view"
                  onClick={() => navigate('/sign-up')}
                >
                  Sign Up
                </button>
              ) : (
                <>
                  {!window.location.href.includes('organization') && (
                    <button
                      className="secondary-btn is-desktop-view"
                      onClick={() => navigate('/organization')}
                    >
                      Dashboard
                    </button>
                  )}
                  <button className="secondary-btn is-desktop-view" onClick={handleLogout}>
                    Logout
                  </button>
                  <IconButton
                    sx={{
                      width: '40px',
                      height: '40px',
                      padding: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Badge badgeContent={notificationCount} color="primary" showZero>
                      <NotificationsNoneIcon
                        sx={{ fontSize: '24px', padding: '0' }}
                        style={{ color: '#021148e6' }}
                        onClick={toggleNotificationsPopup}
                      />
                    </Badge>
                    {notificationsPopup.open && (
                      <NotificationsPopup
                        open={notificationsPopup.open}
                        anchorEl={notificationsPopup.anchorEl}
                        placement={notificationsPopup.placement}
                        handleClose={closeNotificationsPopup}
                      />
                    )}
                  </IconButton>
                </>
              )}
            </div>
            <Box className="hamburger">
              <IconButton
                sx={{ p: 0.5 }}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="#002F7A"
              >
                <img src={ICON} alt="" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
                PaperProps={{
                  style: {
                    boxShadow: '0px 0px 15px rgba(211, 211, 211, 0.5)',
                    padding: '0px 24px',
                    minHeight: 'fit-content',
                  },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.id}
                    onClick={() => handleNavClick(page.link)}
                    sx={{
                      color: location.pathname === page.link ? 'primary' : 'rgba(2, 17, 72, 0.90)',
                    }}
                  >
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
                <div className="buttons-wraper">
                  {!isLoggedIn ? (
                    <button
                      className="secondary-btn is-responsive"
                      onClick={() => navigate('/sign-up')}
                    >
                      Sign Up
                    </button>
                  ) : (
                    <>
                      <button
                        className="secondary-btn is-responsive"
                        onClick={() => navigate('/organization')}
                      >
                        Dashboard
                      </button>
                      <button className="secondary-btn is-responsive" onClick={handleLogout}>
                        Logout
                      </button>
                    </>
                  )}
                </div>
              </Menu>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
export default Navbar;
