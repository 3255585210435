import React, { useState } from 'react';
import InvitesMemberListDetails from '../../OrganizationInvites/InvitesMemberList/InvitesMemberListDetails';
import OrganizationMembers from '../../Org/OrganizationMembers/MembersList';
import {
  Button,
  Box,
  Menu,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';

const tabs = {
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
  CREATOR: 'CREATOR',
  USER: 'USER',
  PENDING: 'PENDING',
};

export default function InvitesMemberList({
  organizationMembers,
  userRole,
  DataFromApiInvites,
  fetchInvite,
  fetchMembers,
  showRole,
  showDeleteButton,
  setUserId,
}) {
  const [searchInput, setSearchInput] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [tab, setTab] = useState(tabs.ONGOING);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filteredInvites, setFilteredInvites] = useState([]);
  const isMenuOpen = Boolean(menuAnchorEl);
  const isFilterActive = selectedFilter !== null;

  const handleSearch = () => {
    const searchTerm = searchInput.trim().toLowerCase();
    if (searchTerm === '') {
      setFilteredMembers([]);
      setFilteredInvites([]);
      setIsSearchPerformed(false);
      return;
    }

    const filteredOrganizationMembers = organizationMembers.filter((member) => {
      const firstName = member.firstName.toLowerCase();
      const lastName = member.lastName.toLowerCase();
      const fullName = `${firstName} ${lastName}`;
      return (
        firstName.startsWith(searchTerm) ||
        lastName.startsWith(searchTerm) ||
        fullName.startsWith(searchTerm)
      );
    });

    const filteredInvites = DataFromApiInvites.filter((invite) => {
      const email = invite.created_for.toLowerCase();
      return email.startsWith(searchTerm);
    });

    setFilteredMembers(filteredOrganizationMembers);
    setFilteredInvites(filteredInvites);
    setIsSearchPerformed(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleFilterClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (selectedTab) => {
    const role = selectedTab.toUpperCase();

    setSearchInput('');
    setIsSearchPerformed(false);

    if (selectedFilter === role) {
      setSelectedFilter(null);
      setFilteredMembers(organizationMembers);
      setFilteredInvites(DataFromApiInvites);
      setTab(null);
    } else {
      if (role === 'PENDING') {
        setFilteredMembers([]);
        setFilteredInvites(DataFromApiInvites);
      } else {
        const filteredMembers = organizationMembers.filter((member) => {
          if (role === 'USER') {
            return member.role === 'USER' || member.role === null;
          }
          return member.role === role;
        });
        setFilteredMembers(filteredMembers);
        setFilteredInvites([]);
      }
      setSelectedFilter(role);
      setTab(selectedTab);
      setIsSearchPerformed(true);
    }
    handleMenuClose();
  };

  return (
    <Box sx={{ minWidth: { md: '490px', xs: '100%' } }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'center', xs: 'flex-start' },
          borderBottom: '1px solid rgba(225, 225, 225, 1)',
          pb: 2,
          flexDirection: { md: 'row', xs: 'column' },
          gap: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: '500',
            letterSpacing: '0.01em',
            color: 'rgba(62, 67, 75, 1)',
          }}
        >
          Members
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <TextField
            id="outlined-read-only-input"
            placeholder="Search by name or email"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              if (e.target.value === '') {
                setFilteredMembers([]);
                setFilteredInvites([]);
                setIsSearchPerformed(false);
              }
            }}
            onKeyPress={handleKeyPress}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid rgba(235, 235, 235, 1)',
                },
                '&:hover fieldset': {
                  border: '1px solid rgba(235, 235, 235, 1)',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid rgba(235, 235, 235, 1)',
                },
              },
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
            }}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {searchInput ? (
                    <IconButton
                      onClick={() => {
                        setSearchInput('');
                        setFilteredMembers([]);
                        setFilteredInvites([]);
                        setIsSearchPerformed(false);
                      }}
                      sx={{
                        padding: 0,
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  ) : (
                    <Search fontSize="small" />
                  )}
                </InputAdornment>
              ),
            }}
          />

          <Button
            startIcon={
              !isFilterActive ? (
                <FilterAltOffOutlinedIcon
                  sx={{
                    color: 'rgba(2, 17, 72, 0.8)',
                  }}
                />
              ) : (
                <FilterAltOutlinedIcon
                  sx={{
                    color: isFilterActive ? 'white' : 'rgba(2, 17, 72, 0.8)',
                  }}
                />
              )
            }
            variant={isFilterActive ? 'contained' : 'outlined'}
            color="primary"
            sx={{
              color: isFilterActive ? 'white' : 'rgba(2, 17, 72, 0.8)',
              border: isFilterActive ? 'none' : '1px solid rgba(235, 235, 235, 1)',
              backgroundColor: isFilterActive ? 'rgba(2, 17, 72, 0.8)' : 'transparent',
              '&:hover': {
                backgroundColor: isFilterActive ? 'rgba(2, 17, 72, 0.9)' : 'rgba(2, 17, 72, 0.05)',
              },
            }}
            onClick={handleFilterClick}
          >
            Filters
          </Button>
          <Menu
            anchorEl={menuAnchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{
              p: 0,
            }}
          >
            {Object.entries(tabs).map(([key, label], index) => (
              <MenuItem
                key={key}
                onClick={() => handleMenuItemClick(label)}
                sx={{
                  color:
                    selectedFilter === label.toUpperCase() ? 'rgba(2, 17, 72, 0.8)' : 'inherit',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: selectedFilter === label.toUpperCase() ? '500' : '400',
                  borderBottom:
                    index !== Object.entries(tabs).length - 1
                      ? '1px solid rgba(235, 235, 235, 1)'
                      : 'none',
                  px: 1.3,
                  py: 0.7,
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>

      <Box
        className="OrganizationInvites-SearchUsers-List custom-scrollbar"
        sx={{
          maxHeight: '289px',
          overflowY: 'auto',
        }}
      >
        {selectedFilter ? (
          <>
            <InvitesMemberListDetails
              member={filteredMembers}
              userRole={userRole}
              showDeleteButton={showDeleteButton}
              showRole={showRole}
              setUserId={setUserId}
            />
            {selectedFilter === 'PENDING' && (
              <OrganizationMembers
                members={filteredInvites}
                showEmail={true}
                inviteSent={true}
                UsedForInvitesSent={true}
                fetchInvite={fetchInvite}
              />
            )}
          </>
        ) : !isSearchPerformed ? (
          <>
            <InvitesMemberListDetails
              member={organizationMembers}
              userRole={userRole}
              fetchMembers={fetchMembers}
              showDeleteButton={showDeleteButton}
              showRole={showRole}
              setUserId={setUserId}
            />
            {DataFromApiInvites && DataFromApiInvites.length > 0 && (
              <OrganizationMembers
                members={DataFromApiInvites}
                showEmail={true}
                inviteSent={true}
                UsedForInvitesSent={true}
                fetchInvite={fetchInvite}
              />
            )}
          </>
        ) : filteredMembers.length > 0 || filteredInvites.length > 0 ? (
          <>
            <InvitesMemberListDetails
              member={filteredMembers}
              userRole={userRole}
              showDeleteButton={showDeleteButton}
              showRole={showRole}
              setUserId={setUserId}
            />
            <OrganizationMembers
              members={filteredInvites}
              showEmail={true}
              inviteSent={true}
              UsedForInvitesSent={true}
              fetchInvite={fetchInvite}
            />
          </>
        ) : (
          <p
            style={{
              color: '#797979',
              fontFamily: 'Poppins',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: '200',
              lineHeight: 'normal',
              alignItems: 'center',
              marginTop: '15px',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            No user found
          </p>
        )}
      </Box>
    </Box>
  );
}
