import React from 'react';
import no_data_img from '../../assets/no_data_img.svg';
import { Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NoData({ Type, Text, NavigateTo }) {
  const navigate = useNavigate();

  const ButtonOnClick = () => {
    navigate(NavigateTo);
  };
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(241, 244, 255, 1)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        p: 4,
        borderRadius: '10px',
      }}
    >
      <img src={no_data_img} alt="" />
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '18px',
          fontWeight: '600',
          color: 'rgba(2, 17, 72, 1)',
        }}
      >
        {Text}
      </Typography>
      {NavigateTo != '' ||
        (NavigateTo != null && (
          <>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '400',
                color: 'rgba(2, 17, 72, 1)',
              }}
            >
              Create {Type} now
            </Typography>
            <Button variant="contained" onClick={ButtonOnClick}>
              CREATE {Type}
            </Button>
          </>
        ))}
    </Box>
  );
}
