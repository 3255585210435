import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DoneIcon from '@mui/icons-material/Done';

ChartJS.register(ArcElement, Tooltip);

export default function DataUsageCard({ TotalStorage, ConsumedStorage, AvailableStorage }) {
  const total = Number(TotalStorage) || 0;
  const consumed = Number(ConsumedStorage) || 0;
  const available = Number(AvailableStorage) || 0;

  const consumedPercentage = total > 0 ? Number(((consumed / total) * 100).toFixed(2)) : 0;

  const chartData = {
    labels: ['Consumed', 'Available'],
    datasets: [
      {
        data: consumedPercentage > 0 ? [consumedPercentage, 100 - consumedPercentage] : [0, 100],
        backgroundColor: ['rgba(40, 81, 226, 1)', '#ECEFFD'],
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.parsed.toFixed(2)}%`;
          },
        },
      },
    },
    cutout: '90%',
  };

  return (
    <Stack
      sx={{
        borderRadius: '8px',
        width: { lg: '35%', md: '50%', xs: '100%' },
        border: '1px solid rgba(236, 239, 253, 1)',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: '500',
          backgroundColor: 'rgba(249, 250, 253, 1)',
          padding: '12px 20px',
        }}
      >
        Data Usage
      </Typography>

      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: '500',
              color: 'rgba(2, 17, 72, 1)',
              marginBottom: '8px',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <DoneIcon sx={{ color: 'rgba(0, 210, 123, 1)' }} fontSize="small" />
            {consumed.toFixed(2)} GB used
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '28px',
              color: 'rgba(62, 67, 75, 0.8)',
            }}
          >
            {available.toFixed(2)} GB total available
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: { lg: '150px', md: '150px', xs: '120px' },
            position: 'relative',
          }}
        >
          <Doughnut data={chartData} options={chartOptions} />
          <Typography
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontWeight: '500',
              fontSize: '28px',
              fontFamily: 'Poppins',
              color: 'rgba(159, 157, 186, 1)',
            }}
          >
            {consumedPercentage.toFixed(2)}%
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}
