import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableContainer,
  Paper,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const moment = require('moment');

const columns = [
  { id: 'id', label: 'ID', minWidth: 170 },
  {
    id: 'updated_at',
    label: 'Date',
    width: '170px',
    format: (value) => {
      const date = moment(value);
      return date.format('DD-MM-YYYY');
    },
  },
  {
    id: 'planName',
    label: 'Plan Name',
    width: '170px',
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'quantity',
    label: 'Duration',
    width: '170px',
    align: 'right',
    format: (value) => {
      if (value === 1) {
        return 'Month';
      } else if (value === 12) {
        return '1 year';
      } else if (value < 12) {
        return `${value} Months`;
      } else {
        const years = Math.floor(value / 12);
        const months = value % 12;
        if (months === 0) {
          return `${years} ${years === 1 ? 'Year' : 'Years'}`;
        } else {
          return `${years} ${years === 1 ? 'Year' : 'Years'} ${months} ${
            months === 1 ? 'Month' : 'Months'
          }`;
        }
      }
    },
  },
  {
    id: 'promoCode',
    label: 'Code Applied',
    width: '170px',
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'amount',
    label: 'Amount',
    width: '170px',
    align: 'left',
    format: (value) => (typeof value === 'number' ? value.toFixed(2) : value),
  },
  {
    id: 'mode',
    label: 'Mode',
    width: '170px',
    align: 'left',
    format: (value) => value,
  },
  {
    id: 'status',
    label: 'Status',
    width: '170px',
    align: 'left',
    format: (value) => {
      let color, backgroundColor, displayText;
      switch (value.toLowerCase()) {
        case 'fulfilled':
          color = 'rgba(9, 124, 76, 1)';
          backgroundColor = 'rgba(230, 248, 239, 1)';
          displayText = 'Successful';
          break;
        case 'failed':
          color = 'rgba(180, 44, 33, 1)';
          backgroundColor = 'rgba(254, 243, 242, 1)';
          displayText = 'Failed';
          break;
        case 'initiated':
          color = 'rgba(184, 88, 31, 1)';
          backgroundColor = 'rgba(249, 245, 231, 1)';
          displayText = 'Initiated';
          break;
        default:
          color = 'rgba(64, 64, 73, 1)';
          backgroundColor = 'transparent';
          displayText = value;
      }
      return { displayText, color, backgroundColor };
    },
  },
];

export default function TransactionTable({ page, setPage, pageCount, transactions }) {
  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, pageCount));
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <style>
        {`
          .custom-scrollbar {
            max-height: 289px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: #354150 #e4efef;
          }

          .custom-scrollbar::-webkit-scrollbar {
            width: 5px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background: #e4efef;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #354150;
            border-radius: 2px;
          }
        `}
      </style>
      <TableContainer className="custom-scrollbar" sx={{ maxHeight: 289 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ border: '1px solid rgba(231, 231, 237, 1)' }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{
                    minWidth: column.minWidth,
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: '500',
                    color: 'rgba(2, 17, 72, 1)',
                    backgroundColor: 'rgba(244, 247, 252, 1)',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    let displayValue, cellStyle;
                    try {
                      if (column.id === 'status') {
                        const { displayText, color, backgroundColor } = column.format(value);
                        displayValue = displayText;
                        cellStyle = {
                          color,
                          backgroundColor,
                          borderRadius: '4px',
                          padding: '4px 8px',
                          display: 'inline-block',
                        };
                      } else {
                        displayValue =
                          value === null ? '~' : column.format ? column.format(value) : value;
                        cellStyle = {};
                      }
                    } catch (error) {
                      console.error(`Error formatting value for column ${column.id}:`, error);
                      displayValue = value;
                      cellStyle = {};
                    }
                    return (
                      <TableCell
                        key={column.id}
                        align="left"
                        style={{
                          width: '170px',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: '400',
                          color: 'rgba(64, 64, 73, 1)',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '170px',
                          position: 'relative',
                        }}
                      >
                        <span style={cellStyle}>{displayValue}</span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          textAlign: 'center',
          padding: '10px',
          backgroundColor: 'rgba(244, 247, 252, 0.75)',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '500',
            color: 'rgba(42, 46, 63, 1)',
          }}
        >
          Page: {page}
          <ArrowBackIosNewIcon
            sx={{
              marginLeft: '5px',
              color: page === 1 ? 'lightgrey' : 'inherit',
            }}
            fontSize="small"
            onClick={handlePrevPage}
          />
          <ArrowForwardIosIcon
            sx={{
              color: page === pageCount || pageCount === 0 ? 'lightgrey' : 'inherit',
            }}
            fontSize="small"
            onClick={handleNextPage}
          />
        </Typography>
      </Box>
    </Paper>
  );
}
