import { AddReactionOutlined, QuickreplyOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { lazy, Suspense, useEffect, useState } from 'react';
import { ChatApi } from '../../api/requests/chat/ChatApi';
import { handleAlert } from '../../utils/handleAlert';

const Picker = lazy(() => import('emoji-picker-react'));

export default function MessageActions({
  message,
  currentUserInfo,
  handleDeleteMessage,
  handleEditMessage,
  handleReplyMessage,
  setHoveredMessageId,
  canSendMessages,
  setReactionsData,
  reactionsData,
}) {
  const [showReactEmoji, setShowReactEmoji] = useState(false);
  const handleAddEmojiReaction = async (emoji) => {
    const emojiData = {
      msgId: message.id,
      emojiData: [
        {
          content: emoji,
          users: [
            {
              id: message.sender_id,
              firstName: currentUserInfo.firstNamme,
              lastName: currentUserInfo.lastName,
            },
          ],
        },
      ],
    };
    let updatedReactionsData = [...reactionsData];
    const messageIndex = updatedReactionsData.findIndex((item) => item.msgId === message.id);
    if (messageIndex !== -1) {
      const emojiIndex = updatedReactionsData[messageIndex].emojiData.findIndex(
        (item) => item.content === emoji
      );
      if (emojiIndex !== -1) {
        updatedReactionsData[messageIndex].emojiData.map((item) => {
          item.users = item.users.filter((user) => user.id !== currentUserInfo.id);
        });
        updatedReactionsData[messageIndex].emojiData = updatedReactionsData[
          messageIndex
        ].emojiData.filter((item) => item.users.length > 0);
        updatedReactionsData[messageIndex].emojiData[emojiIndex].users.push({
          id: currentUserInfo.id,
          firstName: currentUserInfo.firstNamme,
          lastName: currentUserInfo.lastName,
        });
      } else {
        const userIndex = updatedReactionsData[messageIndex].emojiData.findIndex((item) =>
          item.users.some((user) => user.id === currentUserInfo.id)
        );
        if (userIndex !== -1) {
          updatedReactionsData[messageIndex].emojiData.map((item, index) => {
            item.users = item.users.filter((user) => user.id !== currentUserInfo.id);
          });
          updatedReactionsData[messageIndex].emojiData = updatedReactionsData[
            messageIndex
          ].emojiData.filter((item) => item.users.length > 0);
        }
        updatedReactionsData[messageIndex]['emojiData'].push({
          content: emoji,
          users: [
            {
              id: currentUserInfo.id,
              firstName: currentUserInfo.firstNamme,
              lastName: currentUserInfo.lastName,
            },
          ],
        });
      }
    } else {
      updatedReactionsData.push(emojiData);
    }
    setReactionsData(updatedReactionsData);
    try {
      await ChatApi.addEmojiReaction(message.id, emoji);
      setShowReactEmoji(false);
    } catch (error) {
      handleAlert();
    }
  };

  useEffect(() => {
    const preloadEmojiPicker = async () => {
      const PickerModule = await import('emoji-picker-react');
      PickerModule?.default?.preload();
    };
    preloadEmojiPicker();
  }, []);

  return (
    <Box
      onMouseEnter={() => setHoveredMessageId(message.id)}
      onMouseLeave={() => setHoveredMessageId(null)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '8px',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.10)',
        p: 0.5,
        maxHeight: '40px',
        width: 'fit-content',
        left: message.sender_id === currentUserInfo.id ? 'auto' : '',
        pointerEvents: 'auto',
        position: 'absolute',
        right: message.sender_id === currentUserInfo.id ? '10px' : 'auto',
        top: '-30px',
        zIndex: 5,
      }}
    >
      {showReactEmoji && (
        <Suspense>
          <Picker
            style={{
              position: 'absolute',
              // bottom: '45px',
              top: '45px',
              right: message.sender_id === currentUserInfo.id ? '20px' : 'auto',
              left: message.sender_id === currentUserInfo.id ? 'auto' : '20px',
              maxHeight: '300px',
              overflowY: 'auto',
            }}
            searchDisabled={true}
            reactionsDefaultOpen={true}
            height={'400px'}
            onEmojiClick={(e) => handleAddEmojiReaction(e.emoji)}
          />
        </Suspense>
      )}
      <Tooltip title="Add Reaction" placement="top" arrow>
        <IconButton onClick={() => setShowReactEmoji(!showReactEmoji)}>
          <AddReactionOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title={canSendMessages ? 'Quick Reply' : 'Reply disabled'} placement="top" arrow>
        <IconButton onClick={() => canSendMessages && handleReplyMessage(message)} size="small">
          <QuickreplyOutlined fontSize="small" />
        </IconButton>
      </Tooltip>

      {message.sender_id === currentUserInfo.id && (
        <Tooltip title={canSendMessages ? 'Edit' : ''} placement="top" arrow>
          <IconButton onClick={() => canSendMessages && handleEditMessage(message)} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Delete for everyone" placement="top" arrow>
        <IconButton onClick={() => handleDeleteMessage(message.id)} size="small">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
