import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, MenuItem, Menu, Button } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import ContestCardSetting from '../../components/Admin/Components/ContestCardSetting';
import { contestAPI } from '../../api/requests/contests/contestAPI';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NoData from '../../components/NoData';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';

const tabs = {
  UPCOMING: 'UPCOMING',
  ONGOING: 'ONGOING',
  COMPLETED: 'COMPLETED',
};

const SettingsContestComponent = ({ OrganizationName }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [tab, setTab] = useState(tabs.ONGOING);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [tabCount, setTabCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(false);
  const isFilterActive = filteredData !== null;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (selectedTab) => {
    setTab(selectedTab);
    setPage(1);
    handleClose();
  };

  const fetchData = (tab, page) => {
    contestAPI.getUserContests(tab, page).then((data) => {
      setFilteredData(data?.contests);
      setMaxPage(data?.totalPages);
      setTabCount(data?.contests?.length);
    });
  };

  useEffect(() => {
    setLoader(true);
    fetchData(tab, page);
    setLoader(false);
  }, [tab, page]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          justifyContent: 'space-between',
          marginBottom: 3,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: '500',
              letterSpacing: '0.01em',
            }}
          >
            Contests Hosted in your Organization
          </Typography>
        </Box>
        <Box>
          <Button
            startIcon={
              !isFilterActive ? (
                <FilterAltOffOutlinedIcon
                  sx={{
                    color: 'rgba(2, 17, 72, 0.8)',
                  }}
                />
              ) : (
                <FilterAltOutlinedIcon
                  sx={{
                    color: isFilterActive ? 'white' : 'rgba(2, 17, 72, 0.8)',
                  }}
                />
              )
            }
            variant={isFilterActive ? 'contained' : 'outlined'}
            color="primary"
            sx={{
              color: isFilterActive ? 'white' : 'rgba(2, 17, 72, 0.8)',
              border: isFilterActive ? 'none' : '1px solid rgba(235, 235, 235, 1)',
              backgroundColor: isFilterActive ? 'rgba(2, 17, 72, 0.8)' : 'transparent',
              '&:hover': {
                backgroundColor: isFilterActive ? 'rgba(2, 17, 72, 0.9)' : 'rgba(2, 17, 72, 0.05)',
              },
            }}
            onClick={handleClick}
          >
            Filters
          </Button>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {Object.entries(tabs).map(([key, label], index) => (
              <MenuItem
                key={key}
                onClick={() => handleMenuItemClick(label)}
                sx={{
                  color: tab === label ? 'rgba(2, 17, 72, 0.8)' : 'inherit',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: tab === label ? '500' : '400',
                  borderBottom:
                    index !== Object.entries(tabs).length - 1
                      ? '1px solid rgba(235, 235, 235, 1)'
                      : 'none',
                  px: 1.3,
                  py: 0.7,
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>

      {loader ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <CircularProgress />
        </Box>
      ) : filteredData && filteredData.length > 0 ? (
        <Box
          sx={{
            overflowX: 'auto',
            width: '100%',
          }}
        >
          <Box
            display="inline-flex"
            sx={{
              p: 0.6,
              '& > *:not(:last-child)': {
                marginRight: '1rem',
              },
            }}
          >
            {filteredData.map((item) => (
              <ContestCardSetting key={item.id} Contest={item} tab={tab} />
            ))}
          </Box>
        </Box>
      ) : (
        <NoData
          Text={'There are no Contests open!'}
          Type={'Contests'}
          NavigateTo={`/org/${OrganizationName}/contest/user`}
        />
      )}
    </Box>
  );
};

export default SettingsContestComponent;
