import React from 'react';
import '../ViewOrganizations.css';
import MemberDetails from './MemberDetails';
import './MembersList.css';

export default function MembersList({
  members,
  showEmail,
  inviteSent,
  inviteAccepted,
  inviteRejected,
  inviteReceived,
  UsedForInvitesSent,
  sendMail,
  organization,
  setSelectedOrgName,
  requestReceived,
  fetchRequests,
  fetchInvite,
  fetchMembers,
}) {
  return (
    <div
      className={
        organization
          ? 'viewSecondRow-orgDetailsMembersListNotification'
          : 'viewSecondRow-orgDetailsMembersList'
      }
    >
      {members?.map((member) => (
        <div key={member.username}>
          <MemberDetails
            member={member}
            showEmail={showEmail}
            inviteAccepted={inviteAccepted}
            inviteRejected={inviteRejected}
            inviteReceived={inviteReceived}
            requestReceived={requestReceived}
            sendMail={sendMail}
            inviteSent={inviteSent}
            UsedForInvitesSent={UsedForInvitesSent}
            organization={organization}
            setSelectedOrgName={setSelectedOrgName}
            fetchRequests={fetchRequests}
            fetchInvite={fetchInvite}
            fetchMembers={fetchMembers}
          />
        </div>
      ))}
    </div>
  );
}

MembersList.defaultProps = {
  members: [],
};
