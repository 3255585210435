import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LazyComponent } from '../components/LazyLoader';
import PrivateRoute from './PrivateRoute';

const DocManagement = LazyComponent(() => import('../Container/DocManagementContainer'));
const FolderById = LazyComponent(() => import('../Container/FolderBy/FolderByIdContainer'));

export const DocManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/file" element={<DocManagement />} />
      <Route path="/file/:id" element={<FolderById />} />
    </Routes>
  );
};
