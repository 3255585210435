import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, IconButton, Typography } from '@mui/material';
import orgLogo from '../../assets/LandingPage/ocean-engine.svg';
import denyIcon from '../../assets/LandingPage/Org/denyIcon.svg';
import acceptIcon from '../../assets/LandingPage/Org/acceptIcon.svg';
import { Modal } from '@mui/material';
import { styled } from '@mui/system';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import './Invite.css';
import CloseIcon from '@mui/icons-material/Close';

const ModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '24px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: theme.spacing(2),
}));
const ModalFooter = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
}));

const Invite = ({ inviteId, setSelectedOrgName }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [inviteInfo, setInviteInfo] = useState();
  useEffect(() => {
    organizationAPI
      .getInvite(inviteId)
      .then((data) => {
        setInviteInfo(data.inviteData);
        setLoading(false);
      })
      .catch(() => {
        handleClose();
      });
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const acceptInvite = () => {
    organizationAPI
      .inviteAction(inviteId, 'ACCEPT')
      .then(() => {
        window.location.replace(`/org/${inviteInfo?.organizationdata?.name}/dashboard`);
        localStorage.setItem('orgName', inviteInfo?.organizationdata?.name);
      })
      .catch(() => handleClose());
  };
  const rejectInvite = () => {
    organizationAPI
      .inviteAction(inviteId, 'REJECT')
      .then(() => window.location.replace('/organization'))
      .catch(() => handleClose());
  };

  return (
    <div>
      <Modal
        open={open && !loading}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <div className="inviteActionModal ">
          <ModalHeader>
            <Box>
              <Typography variant="h6" id="modal-title" sx={{ mb: '4px' }}>
                You have received an Invite:
              </Typography>
              <Typography variant="body2" id="modal-description">
                View and respond to organizations invitations and thoughtfully express your decision
                to accept or decline the invitations.
              </Typography>
            </Box>
            <IconButton onClick={handleClose} className="inviteActionClose">
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <div className="inviteActionCon">
            <div className="inviteActionOrg">
              <img
                width={'60px'}
                height={'60px'}
                style={{ objectFit: 'cover' }}
                srcSet={[inviteInfo?.organizationdata?.orglink, orgLogo]}
                alt=""
              />
              <div className="inviteActionOrgName">
                <Typography variant="h5" id="modal-title" sx={{ pb: '4px' }}>
                  {inviteInfo?.organizationdata?.name}
                </Typography>
                <Typography variant="body2" id="modal-description" sx={{ color: '#8b8b8b' }}>
                  {inviteInfo?.organizationdata?.domain}
                </Typography>
              </div>
            </div>
          </div>
          <ModalFooter>
            <Button
              variant="contained"
              color="error"
              sx={{
                minWidth: '120px',
                height: '36px',
                padding: '6px 16px',
                width: 'fit-content',
                gap: '8px',
                justifyContent: 'space-between',
              }}
              onClick={rejectInvite}
              endIcon={<img width="24px" height="24px" src={denyIcon} alt="" />}
            >
              Deny
            </Button>
            <Button
              variant="contained"
              sx={{
                minWidth: '120px',
                height: '36px',
                padding: '6px 16px',
                width: 'fit-content',
                gap: '8px',
              }}
              onClick={acceptInvite}
              endIcon={<img width="20px" height="20px" src={acceptIcon} alt="" />}
            >
              Accept
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default Invite;
