import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import RootReducer from './Redux/Index';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
const store = createStore(RootReducer);

Bugsnag.start({
  apiKey: '9858f32962a6875797425297a9130a5b',
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: '9858f32962a6875797425297a9130a5b' });
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // redux store provider
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
);

serviceWorkerRegistration.register();
