import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import TransactionTable from '../../../TransactionTable/index';
import { organizationAPI } from '../../../../api/requests/organization/organizationAPI';
import { plansAPI } from '../../../../api/requests/plans';
import { handleAlert } from '../../../../utils/handleAlert';
import Skeletons from '../../../../components/Skeleton/Skeletons';
import PlanCard from '../../../Organization/PlanCard';
import DoneIcon from '@mui/icons-material/Done';
import Badge from '@mui/material/Badge';
import { getOrgName } from '../../../../utils/appendOrgQuery';

export default function OrganizationTransactions() {
  const [isLoading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [plansData, setPlansData] = useState({ currentPlan: [], availablePlans: [] });
  const orgName = getOrgName();

  async function getTransactions() {
    setLoading(true);
    try {
      const response = await organizationAPI.getOrgTransaction(page);
      setPageCount(response.pages);
      setTransactions(response.transactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getTransactions();
  }, [page]);

  useEffect(() => {
    setLoading(true);
    plansAPI
      .getPlans()
      .then((response) => {
        setPlansData(response);
        localStorage.setItem('currentPlan', JSON.stringify(response?.currentPlan[0]));
        setLoading(false);
      })
      .catch((err) => {
        handleAlert('Something went wrong! please try again later', 'error');
        setLoading(false);
      });
  }, []);
  const currentPlan = plansData.currentPlan[0] || {};
  const premiumPlan = plansData.availablePlans.find((plan) => plan.name === 'Premium Plan') || {};

  return isLoading ? (
    <Skeletons type="CircularLoader" />
  ) : (
    <Box sx={{ pt: '20px' }}>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: '500',
            color: 'rgba(62, 67, 75, 1)',
            mb: 2,
          }}
        >
          Plan
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            gap: 2,
          }}
        >
          <Badge
            badgeContent={<DoneIcon sx={{ color: 'rgba(255, 255, 255, 1)' }} fontSize="small" />}
            color="secondary"
            sx={{
              width: { md: '35%', xs: '100%' },
              minWidth: '320px',

              '& .MuiBadge-badge': {
                right: 2,
                top: 3,
                backgroundColor: 'rgba(105, 138, 255, 1)',
                width: '22px',
                height: '22px',
                borderRadius: '100px',
              },
            }}
          >
            <Box sx={{ width: '100%' }}>
              <PlanCard
                data={currentPlan}
                BgColor={'rgba(245, 247, 255, 1)'}
                ButtonText={'Check Plan'}
                isCurrent={true}
                orgName={orgName}
                showValidity={currentPlan.price != 0 ? true : false}
              />
            </Box>
          </Badge>

          {(currentPlan.price === 0 || currentPlan.price === 5000) && (
            <Box sx={{ width: { md: '35%', xs: '100%' } }}>
              <PlanCard
                data={premiumPlan}
                BgColor={'rgba(105, 138, 255, 1)'}
                ButtonText={'Upgrade Now'}
                isCurrent={false}
                orgName={orgName}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: '20px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: '500',
            color: 'rgba(62, 67, 75, 1)',
            my: 2,
          }}
        >
          Billing History
        </Typography>
        <TransactionTable
          transactions={transactions}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
        />
      </Box>
    </Box>
  );
}
