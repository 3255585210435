import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'linear-gradient(50.08deg, #002F7A -16.9%, #254FE0 -16.89%, #698AFF 87.11%)',
  },
}));

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({ theme, checked }) => ({
    margin: 0,
    padding: '16px 58px 16px 16px',
    backgroundColor: checked ? 'rgba(105, 138, 255, 0.06)' : 'white',
    border: checked ? '1px solid rgba(105, 138, 255, 1)' : '1px solid rgba(234, 234, 234, 1)',
    borderRadius: theme.shape.borderRadius,
    '.MuiFormControlLabel-label': {
      width: '100%',
      paddingLeft: '10px',
    },
  })
);

const LabelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const PrimaryText = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: '0.02em',
  textAlign: 'left',
  color: 'rgba(2, 17, 72, 0.9)',
  paddingBottom: '10px',
});

const SecondaryText = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0.01em',
  textAlign: 'left',
  color: 'rgba(120, 120, 120, 1)',
});

export default function StartOrJoinOrganization({ setStep }) {
  const [selectedOption, setSelectedOption] = useState('first');
  const navigate = useNavigate();

  const handleContinue = () => {
    if (selectedOption === 'first') {
      navigate('?create=true');
      setStep(1);
    } else if (selectedOption === 'second') {
      navigate('?join=true');
      setStep(-1);
    }
  };

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return (
      <StyledFormControlLabel
        checked={checked}
        {...props}
        control={<Radio sx={{ alignSelf: 'flex-start', mt: 0.5 }} />}
        label={
          <LabelWrapper>
            <PrimaryText>{props.primaryText}</PrimaryText>
            <SecondaryText>{props.secondaryText}</SecondaryText>
          </LabelWrapper>
        }
      />
    );
  }

  MyFormControlLabel.propTypes = {
    value: PropTypes.any,
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', m: 2 }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '32px',
            fontWeight: 500,
            color: 'rgba(2, 17, 72, 0.9)',
          }}
        >
          What would you like to do first?
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 400,
            color: 'rgba(120, 120, 120, 1)',
            mt: '12px',
            mb: '40px',
          }}
        >
          Select the below field to get started
        </Typography>
      </Box>

      <RadioGroup
        name="use-radio-group"
        defaultValue="first"
        value={selectedOption}
        sx={{ width: { md: '90%', lg: '65%', xs: '90%' }, gap: 2 }}
        onChange={(e) => setSelectedOption(e.target.value)}
      >
        <MyFormControlLabel
          value="first"
          primaryText="Start a New Organization"
          secondaryText="Create and set up your own organization."
        />
        <MyFormControlLabel
          value="second"
          primaryText="Join an Existing Organization"
          secondaryText="Enter your organization code to join."
        />
      </RadioGroup>
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 4,
        }}
      >
        <BorderLinearProgress variant="determinate" value={12.5} />
      </Box>
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 5,
          mb: 2,
        }}
      >
        <Button variant="contained" onClick={handleContinue}>
          Continue
        </Button>
      </Box>
    </>
  );
}
