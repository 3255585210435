import React, { useState, useEffect } from 'react';
import { Box, Divider, Avatar, TextField, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import { handleAlert } from '../../utils/handleAlert';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import handleFileUpload from '../../api/axios/fileUpload';
import axios from 'axios';
import NameUpdateModal from './NameUpdateModal';
import { useNavigate } from 'react-router-dom';

export default function EditOrganizationModal({
  open,
  onClose,
  organizationName,
  organizationDescription,
  organizationPhotoLink,
  organizationId,
  fetchData,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '40%',
    maxHeight: isMobile ? '80vh' : 'auto',
    overflowY: isMobile ? 'auto' : 'visible',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '24px',
    borderRadius: '12px',
  };
  const [description, setDescription] = useState(organizationDescription);
  const [photoLink, setPhotoLink] = useState(organizationPhotoLink);
  const [noedit, setNoEdit] = useState(true);
  const [title, setTitle] = useState('');
  const [orgNameError, setOrgNameError] = useState('');
  const [cancelToken, setCancelToken] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showNameUpdateModal, setShowNameUpdateModal] = useState(false);

  useEffect(() => {
    setTitle(organizationName);
    setDescription(organizationDescription);
    setPhotoLink(organizationPhotoLink);
    setNoEdit(true);
  }, [organizationName, organizationDescription, organizationPhotoLink]);
  useEffect(() => {}, [showNameUpdateModal]);
  const handleRemovePhoto = () => {
    setPhotoLink('');
    handleAlert('Photo removed', 'success');
  };

  const handleUploadPhoto = async (event) => {
    if (event.target.files && event.target.files.length > 0 && event.target.files[0]) {
      setIsUploading(true);
      const reference = 'ORGANIZATION_DATA';
      const source = axios.CancelToken.source();
      setCancelToken(source);
      try {
        const url = await handleFileUpload(event.target.files[0], source.token, reference);
        if (url) {
          setPhotoLink(url);
          handleAlert('Image has been uploaded', 'success');
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          handleAlert('Upload cancelled', 'info');
        } else {
          handleAlert('Failed to upload image', 'error');
        }
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleCancelUpload = () => {
    if (cancelToken) {
      cancelToken.cancel('Upload cancelled by the user');
      setCancelToken(null);
    }
  };

  const handleSave = async () => {
    if (title !== organizationName) {
      setShowNameUpdateModal(true);
    } else {
      updateOrganization(false);
    }
  };

  const updateOrganization = async (shouldLogout) => {
    var info = {
      name: title,
      description: description,
      logoLink: photoLink,
    };
    try {
      await organizationAPI.updateOragnizationDetails(info, organizationId);
      await fetchData();
      onClose();
      if (shouldLogout) {
        localStorage.clear();
        navigate('/sign-in');
      }
    } catch (error) {
      handleAlert('Failed to update organization details', 'error');
    }
  };
  function validateName(e) {
    const newTitle = e.target.value;
    setTitle(newTitle);

    if (newTitle.length === 0) {
      setOrgNameError('Organization name cannot be empty');
      setNoEdit(true);
      return;
    }

    const re = /^[a-zA-Z_]+$/;
    if (re.test(newTitle)) {
      setOrgNameError('');
      setNoEdit(newTitle === organizationName);
    } else {
      setOrgNameError('Only capital and small letters, along with underscores, are allowed');
      setNoEdit(true);
    }
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          sx={{
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: '500',
            mb: 2,
          }}
        >
          Edit Organization Profile
        </Typography>
        <Divider />
        <Typography
          id="modal-modal-description"
          sx={{
            mt: 2,
            mb: 2,
            border: '1px solid rgba(252, 74, 74, 1)',
            p: '16px',
            borderRadius: '8px',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(120, 120, 120, 1)',
          }}
        >
          <span
            style={{
              color: 'rgba(252, 74, 74, 1)',
            }}
          >
            Warning:
          </span>{' '}
          Changing the organization title, description, or logo will update these details across all
          associated platforms. Proceed with caution.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            gap: 2,
          }}
        >
          <Box sx={{ mb: 2, maxWidth: '250px' }}>
            <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400', mb: 2 }}>
              Logo
            </Typography>
            {photoLink ? (
              <Avatar
                alt={title}
                src={photoLink}
                sx={{
                  width: '221px',
                  height: '200px',
                  borderRadius: '10px',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  width: { md: '221px', xs: 'auto' },
                  height: { md: '200px', xs: '150px' },
                  maxWidth: '250px',
                  borderRadius: '10px',
                  bgcolor: 'rgba(241, 244, 255, 1)',
                  color: 'rgba(105, 138, 255, 1)',
                  fontFamily: 'Roboto',
                  fontSize: '36px',
                  fontWeight: '800',
                }}
              >
                {organizationName ? organizationName.charAt(0).toUpperCase() : ''}
              </Avatar>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'space-between',
                mt: 2,
              }}
            >
              <Button
                onClick={handleRemovePhoto}
                variant="outlined"
                disabled={!photoLink}
                sx={{
                  padding: '8px 10px',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                Remove
              </Button>
              <Button
                variant="outlined"
                component="label"
                onClick={isUploading ? handleCancelUpload : null}
                sx={{
                  padding: '8px 10px',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {isUploading ? 'Cancel Upload' : 'Upload Photo'}
                {!isUploading && (
                  <input type="file" hidden accept="image/*" onChange={handleUploadPhoto} />
                )}
              </Button>
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400', mb: 2 }}>
              Details
            </Typography>
            <TextField
              fullWidth
              label="Organization Name"
              variant="outlined"
              value={title}
              onChange={(e) => validateName(e)}
              error={!!orgNameError}
              helperText={orgNameError}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Organization Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button onClick={onClose} fullWidth variant="outlined">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} fullWidth disabled={noedit}>
            Save
          </Button>
        </Box>
        {showNameUpdateModal && (
          <NameUpdateModal
            open={showNameUpdateModal}
            onClose={() => setShowNameUpdateModal(false)}
            onConfirm={() => updateOrganization(true)}
          />
        )}
      </Box>
    </Modal>
  );
}
