import React, { useState, useEffect } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Button, Box, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { handleAlert } from '../../utils/handleAlert';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import handleFileUpload from '../../api/axios/fileUpload';
import LoadingButton from '@mui/lab/LoadingButton';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'linear-gradient(50.08deg, #002F7A -16.9%, #254FE0 -16.89%, #698AFF 87.11%)',
  },
}));

export default function OrganizationDetails({ setStep }) {
  const [organizationName, setOrganizationName] = useState('');
  const [description, setDescription] = useState('');
  const [photoLink, setPhotoLink] = useState('');
  const [cancelToken, setCancelToken] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationSize, setOrganizationSize] = useState('');
  const [organizationPeople, setOrganizationPeople] = useState('');
  const [organizationSolution, setOrganizationSolution] = useState('');
  const [hearAboutUs, setHearAboutUs] = useState('');

  useEffect(() => {
    const orgName = localStorage.getItem('orgName');
    const orgSize = localStorage.getItem('orgSize');
    const orgPeople = localStorage.getItem('orgPeople');
    const orgSolution = localStorage.getItem('orgSolution');
    const HearAboutUs = localStorage.getItem('HearAboutUs');

    if (orgName) {
      setOrganizationName(orgName);
    }
    if (orgSize) {
      setOrganizationSize(orgSize);
    }
    if (orgPeople) {
      setOrganizationPeople(orgPeople);
    }
    if (orgSolution) {
      setOrganizationSolution(orgSolution);
    }
    if (HearAboutUs) {
      setHearAboutUs(HearAboutUs);
    }
    getOrgData();
  }, []);

  async function getOrgData() {
    try {
      const response = await organizationAPI.getOrganizations();
      if (response && response.length > 0) {
        setOrganizationId(response[0].id);
      }
    } catch (err) {
      console.error('Error fetching organizations:', err);
    }
  }

  const handleUploadPhoto = async (event) => {
    if (event.target.files && event.target.files.length > 0 && event.target.files[0]) {
      setIsUploading(true);
      const reference = 'ORGANIZATION_DATA';
      const source = axios.CancelToken.source();
      setCancelToken(source);
      try {
        const url = await handleFileUpload(event.target.files[0], source.token, reference);
        if (url) {
          setPhotoLink(url);
          handleAlert('Image has been uploaded', 'success');
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          handleAlert('Upload cancelled', 'info');
        } else {
          handleAlert('Failed to upload image', 'error');
        }
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleCancelUpload = () => {
    if (cancelToken) {
      cancelToken.cancel('Upload cancelled by the user');
      setCancelToken(null);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const info = {
      description: description,
      logoLink: photoLink,
    };
    const body = {
      size: organizationSize,
      people: organizationPeople,
      solution: organizationSolution,
      hearAboutUs: hearAboutUs,
      organizationId: organizationId,
    };
    try {
      await organizationAPI.putOrgMetadata(body);
    } catch (err) {}

    try {
      await organizationAPI.updateOragnizationDetails(info, organizationId);
      setStep(7);
      setLoading(false);
    } catch (error) {
      handleAlert('Failed to update organization details', 'error');
      setLoading(false);
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '32px',
          fontWeight: 500,
          color: 'rgba(2, 17, 72, 0.9)',
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 1,
        }}
      >
        Let's Finalize Your{' '}
        <span
          style={{
            color: 'rgba(105, 138, 255, 1)',
          }}
        >
          {organizationName}
        </span>{' '}
        organization Details
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: 400,
          color: 'rgba(120, 120, 120, 1)',
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: '12px',
          mb: '40px',
        }}
      >
        Provide the following details to complete your organization setup.
      </Typography>
      <TextField
        id="outlined-basic"
        label="Tell Us About Your Organization"
        variant="outlined"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={{ width: { md: '90%', lg: '65%', xs: '90%' } }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 4,
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgba(160, 160, 160, 1)',
            mb: 2,
          }}
        >
          Logo
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '110.5px',
              height: '100px',
              borderRadius: '10px',
              backgroundColor: 'rgba(241, 244, 255, 1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '36px',
                fontWeight: 800,
                color: 'rgba(105, 138, 255, 1)',
              }}
            >
              {organizationName.charAt(0)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
            <Button color="primary" variant="outlined">
              Remove
            </Button>
            <Button
              variant="outlined"
              component="label"
              onClick={isUploading ? handleCancelUpload : undefined}
            >
              {isUploading ? 'Cancel Upload' : 'Upload logo'}
              {!isUploading && (
                <input type="file" hidden accept="image/*" onChange={handleUploadPhoto} />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 4,
        }}
      >
        <BorderLinearProgress variant="determinate" value={75} />
      </Box>
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 5,
          mb: 2,
        }}
      >
        <LoadingButton variant="contained" onClick={handleSave} loading={loading}>
          Continue
        </LoadingButton>
      </Box>
    </>
  );
}
