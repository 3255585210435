import React, { useRef } from 'react';
import {
  Button,
  Stack,
  Modal,
  CircularProgress,
  TextField,
  Typography,
  Chip,
  InputAdornment,
} from '@mui/material';
import { useState } from 'react';
import { organizationAPI } from '../../../../api/requests/organization/organizationAPI';
import { handleAlert } from '../../../../utils/handleAlert';
import { Send } from '@mui/icons-material';

export default function InviteWithMailModal({ open, setOpen, fetchInvites }) {
  const [mailInput, setMailInput] = useState('');
  const [mailArray, setMailArray] = useState([]);
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [invalidEmails, setInvalidEmails] = useState([]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddEmails = () => {
    const emails = mailInput.split(/[\s,;]+/).filter((email) => email.length > 0);
    const validEmails = emails.filter((email) => isValidEmail(email));
    const invalidEmails = emails.filter((email) => !isValidEmail(email));
    if (invalidEmails.length > 0) {
      handleAlert(`Invalid emails: ${invalidEmails.join(', ')}`, 'warning');
    }

    const combinedEmails = [...mailArray, ...validEmails];
    const uniqueEmails = Array.from(new Set(combinedEmails));

    setMailArray(uniqueEmails);
    setInvalidEmails(invalidEmails);
    setMailInput(invalidEmails.join(' '));
    inputRef.current.blur();
  };

  const handleDeleteEmail = (emailToDelete) => {
    setMailArray(mailArray.filter((email) => email !== emailToDelete));
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      const email = e.target.value.trim();
      const lastEmail = email.split(' ').pop();

      if (isValidEmail(lastEmail)) {
        setMailArray([...mailArray, lastEmail]);
        setMailInput('');
      } else {
        setMailInput(email);
        setInvalidEmails([lastEmail]);
      }
    }

    if (e.key === 'Enter') {
      handleAddEmails();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const clipboardData = e.clipboardData.getData('Text');
    const emails = clipboardData.split(/[\s,;]+/).filter((email) => email.length > 0);
    const validEmails = emails.filter((email) => isValidEmail(email));
    const invalidEmails = emails.filter((email) => !isValidEmail(email));

    setMailArray((prev) => {
      const uniqueEmails = Array.from(new Set([...prev, ...validEmails]));
      return uniqueEmails;
    });

    setInvalidEmails((prev) => [...prev, ...invalidEmails]);
    setMailInput(invalidEmails.join(' '));
  };

  async function sendInvite() {
    if (mailArray.length > 0) {
      setLoading(true);
      if (invalidEmails.length > 0) {
        handleAlert(`Invalid emails: ${invalidEmails.join(', ')}`, 'warning');
        setLoading(false);
      } else {
        try {
          await organizationAPI.inviteViaEmail({
            note: note,
            emails: mailArray,
          });
          fetchInvites();
          handleAlert('Invites sent successfully', 'success');
          setMailArray([]);
          setNote('');
          setOpen(false);
        } catch (error) {
          handleAlert(error.message, 'error');
        } finally {
          setLoading(false);
        }
      }
    } else {
      handleAlert('Please enter at least one valid email', 'warning');
    }
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '500px', xs: '80vw' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: '24px',
          gap: '8px',
          borderRadius: '12px',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: '500',
            borderBottom: '1px solid rgba(225, 225, 225, 1)',
            pb: 2,
            mb: 3,
            fontFamily: 'Roboto',
            color: 'rgba(21, 24, 33, 0.8)',
          }}
        >
          Invite Members
        </Typography>

        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            color: 'black',
          }}
        >
          Add one or more emails to invite your team members (separate by spaces, commas, or
          semicolons)
        </Typography>
        <TextField
          placeholder="Enter email addresses"
          helperText="Press Enter to add the email addresses"
          size="small"
          onChange={(e) => {
            setMailInput(e.target.value);
            if (e.target.value.trim() === '') {
              setInvalidEmails([]);
            } else if (
              e.nativeEvent.inputType !== 'insertText' ||
              e.target.value.slice(-1) !== ' '
            ) {
              setInvalidEmails([]);
            }
          }}
          onPaste={handlePaste}
          value={mailInput}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
          InputProps={{
            endAdornment: invalidEmails.length > 0 && (
              <InputAdornment position="end">
                <Typography variant="caption" color="red">
                  {`Invalid emails detected`}
                </Typography>
              </InputAdornment>
            ),
          }}
          sx={{
            '& input': {
              color: invalidEmails.length > 0 ? 'red' : 'inherit',
            },
            '& fieldset': {
              borderColor: invalidEmails.length > 0 ? 'red' : 'inherit',
            },
          }}
        />

        <Stack
          direction={'row'}
          sx={{
            maxWidth: '100%',
            flexWrap: 'wrap',
            gap: '4px',
            maxHeight: '105px',
            overflow: 'auto',
            mt: 1,
          }}
        >
          {mailArray.map((email, index) => (
            <Chip key={index} label={email} onDelete={() => handleDeleteEmail(email)} />
          ))}
        </Stack>
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            color: 'black',
            mt: 2,
          }}
        >
          Add a note to your invite
        </Typography>
        <TextField
          placeholder="Enter your note/message here"
          minRows={2}
          maxRows={4}
          multiline
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{
            mt: 2,
          }}
          endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Send />}
          onClick={sendInvite}
          disabled={loading || mailArray.length === 0 || invalidEmails.length > 0}
        >
          Send Invite
        </Button>
      </Stack>
    </Modal>
  );
}
