import React, { useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Button, Box, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import JoinOrgPopUp from '../../components/LandingPage/Org/JoinOrgPopup';
import LoadingButton from '@mui/lab/LoadingButton';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'linear-gradient(50.08deg, #002F7A -16.9%, #254FE0 -16.89%, #698AFF 87.11%)',
  },
}));

export default function JoinOrganization() {
  const [isJoinOrgPopupVisible, setJoinOrgPopupVisible] = useState(false);
  const [joinBtnClickedWithoutCode, setJoinBtnClickedWithoutCode] = useState(false);
  const [orgCodeEntered, setOrgCodeEntered] = useState('');
  const [isCodeEntered, setIsCodeEntered] = useState(false);
  const [loading, setLoading] = useState(false);

  const openJoinOrgPopup = () => {
    setLoading(true);
    if (isCodeEntered) {
      setJoinOrgPopupVisible(true);
      setJoinBtnClickedWithoutCode(false);
    } else {
      setJoinBtnClickedWithoutCode(true);
    }
    localStorage.removeItem('onboardingStep');
    setLoading(false);
  };
  const handleCodeInputChange = (e) => {
    const codeEntered = e.target.value;
    setOrgCodeEntered(codeEntered);
    setIsCodeEntered(!!codeEntered.trim());
    setJoinBtnClickedWithoutCode(false);
  };
  const closeJoinOrgPopup = () => {
    setJoinOrgPopupVisible(false);
  };
  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '32px',
          fontWeight: 500,
          color: 'rgba(2, 17, 72, 0.9)',
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 1,
        }}
      >
        Let's Get You Into Your Organization
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: 400,
          color: 'rgba(120, 120, 120, 1)',
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: '12px',
          mb: '40px',
        }}
      >
        Enter your organization code below to join.
      </Typography>
      <TextField
        id="outlined-basic"
        label="Enter organization code"
        variant="outlined"
        onChange={handleCodeInputChange}
        helperText={joinBtnClickedWithoutCode ? 'Enter the code to join' : ''}
        sx={{ width: { md: '90%', lg: '65%', xs: '90%' } }}
      />
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 4,
        }}
      >
        <BorderLinearProgress variant="determinate" value={100} />
      </Box>
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 5,
          mb: 2,
        }}
      >
        {/* <Button variant="outlined" startIcon={<ArrowBackIosIcon />}>
          Back
        </Button> */}
        <LoadingButton
          variant="contained"
          onClick={() => {
            openJoinOrgPopup();
          }}
          loading={loading}
        >
          Join Now
        </LoadingButton>
      </Box>
      {isJoinOrgPopupVisible && <JoinOrgPopUp onClose={closeJoinOrgPopup} code={orgCodeEntered} />}
    </>
  );
}
