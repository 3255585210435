import React from 'react';
import { Stack, Modal, Typography, Button } from '@mui/material';
export default function NameUpdateModal({ open, onClose, onConfirm }) {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '400px' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          gap: '16px',
          borderRadius: '20px',
        }}
      >
        <Typography>
          Are you sure you want to change the Organization name? This will lead to signing out.
        </Typography>
        <Stack direction="row" width="100%" gap="8px">
          <Button
            variant="outlined"
            fullWidth
            sx={{
              borderRadius: '16px',
              fontSize: { xs: '14px', sm: '16px' },
              textTransform: 'none',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: '16px',
              fontSize: { xs: '14px', sm: '16px' },
              textTransform: 'none',
              backgroundColor: '#93A9FA',
              '&:hover': { backgroundColor: '#93A9FA' },
            }}
            fullWidth
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
