import React from 'react';
import { Box, Typography } from '@mui/material';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import TaskIcon from '@mui/icons-material/Task';
import { useNavigate } from 'react-router-dom';
import { getOrgName } from '../../../utils/appendOrgQuery';

export default function SuggestedFeatures() {
  const navigate = useNavigate();
  const orgName = getOrgName();
  return (
    <Box
      sx={{
        backgroundColor: '#F6F6F6',
        width: { lg: '50%', md: '90%', xs: '100%' },
        margin: '0 auto',
        p: 2,
        borderRadius: '30px',
        mb: 2,
        mt: 2,
      }}
    >
      <Typography
        variant="h7"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 2,
        }}
      >
        Suggested features to enhance your experiance
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: { lg: 'row', md: 'column', xs: 'row' },
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 2,
            backgroundColor: '#E1E1E1',
            alignItems: 'center',
            borderRadius: '20px',
            width: '100px',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(`/org/${orgName}/task/created`);
          }}
        >
          <TaskIcon />
          Task
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 2,
            backgroundColor: '#E1E1E1',
            alignItems: 'center',
            borderRadius: '20px',
            width: '100px',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(`/org/${orgName}/course/user`);
          }}
        >
          <ImportContactsOutlinedIcon />
          Course
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 2,
            backgroundColor: '#E1E1E1',
            alignItems: 'center',
            borderRadius: '20px',
            width: '100px',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(`/org/${orgName}/contest/user`);
          }}
        >
          <EditCalendarIcon />
          Contest
        </Box>
      </Box>
    </Box>
  );
}
