export const ASSET_CONFIGS = {
  HOME_PAGE_BANNER_1: 'home_page_banner_1_new',
  HOME_PAGE_BANNER_2: 'home_page_banner_2',
  CONTEST_BANNER_UP: 'contest_banner_up',
  CODING_CONTEST_BANNER: 'contest_banner_codingcontest',
  CONTEST_ALL_RANKING_BANNER: 'contest_all_ranking_banner',
  UPCOMING_CONTEST_CAROUSEL_BANNER: 'upcoming_contest_carousel_banner',
  CONTEST_CONTESTID_RANKING: 'contest_contestid_ranking',
  CONTEST_BANNER_ID: 'contest_banner_id',
  WISHLIST_BANNER: 'wishlist_banner',
  SIGN_UP_LOGO: 'sign_up_logo',
  SIGN_UP_LOGO_PHONE: 'sign_up_logo_phone',
  SIGN_IN_LOGO: 'sign_in_logo',
  SIGN_IN_LOGO_PHONE: 'sign_in_logo_phone',
  FORGET_PASSWORD_LOGO: 'forgot_passward_logo',
  FORGET_PASSWORD_LOGO_PHONE: 'forgot_passward_logo_phone',
  CONTEST_LOGO_NAVBAR: 'contact_logo_navbar',
  CONTACT_LOGO_FOOTER: 'contact_logo_footer',
  SIDE_BAR_LOGO: 'side_bar_logo_big',
  HOME_NAVBAR_PHONE: 'home_navbar_phone',
  MY_COURSES_COURSE_VIDEOS_NAVBAR: 'my_courses_course_videos_navbar',
};
