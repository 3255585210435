export const CREATE_TASK = "CREATE_TASK";
export const CREATE_SUBTASK = "CREATE_SUBTASK";
export const SET_ACTIVE_COMPONENT = "SET_ACTIVE_COMPONENT";
export const SET_SUBTASKS = "SET_SUBTASKS";
export const SET_TASK_CREATED = "SET_TASK_CREATED";
export const SET_TASK_ITEMS = "SET_TASK_ITEMS";
export const SET_TASKS = "SET_TASKS";
export const ADD_ASSIGNEE = "ADD_ASSIGNEE";
export const DELETE_ASSIGNEE = "DELETE_ASSIGNEE";
export const SET_SELECTED_SUBTASK = "SET_SELECTED_SUBTASK";
export const SET_SELECTED_SECTIONS = "SET_SELECTED_SECTIONS";
export const SET_CURRENT_TASK = "SET_CURRENT_TASK";
export const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS";
export const VIEW_TASK = "VIEW_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const DELETE_SUBTASK = "DELETE_SUBTASK";
export const EDIT_TASK = "EDIT_TASK";
export const EDIT_SUBTASK = "EDIT_SUBTASK";
export const SET_TASK_PROGRESS = "SET_TASK_PROGRESS";
export const SET_ASSIGNEE_ID = "SET_ASSIGNEE_ID";
export const FILE_POST_DATA = "FILE_POST_DATA";
export const CHANGE_POST_DATA = "CHANGE_POST_DATA";
export const FETCH_TASK_PROGRESS = "FETCH _TASK_PROGRESS"
export const DELETE_ALL_ASSIGNEES = "DELETE_ALL_ASSIGNEES"
