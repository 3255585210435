import React, { useEffect } from 'react';
import { Stack, Typography, Box, Divider } from '@mui/material';
import InvitesMemberList from '../../components/LandingPage/OrganizationInvites/InvitesMemberList/InvitesMemberList';
import { useState } from 'react';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import Skeletons from '../../components/Skeleton/Skeletons';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CourseAccordion from '../../components/Organization/CourseAccordion';
import ContestAccordion from '../../components/Organization/ContestAccordion';
import TaskAccordion from '../../components/Organization/TaskAccordion';
import NoData from '../../components/NoData';

export default function MemberReport() {
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [isMemberLoading, setIsMemberLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [courses, setCourses] = useState([]);
  const [contests, setContests] = useState([]);
  const drawerWidth = 200;

  const fetchOrganizationMembers = async () => {
    try {
      setIsMemberLoading(true);
      const responseMemberList = await organizationAPI.getMembersList();
      const filteredMembers = responseMemberList.members.filter(
        (member) => member.role !== 'CREATOR'
      );
      setOrganizationMembers(filteredMembers);
      setIsMemberLoading(false);
    } catch (error) {
      console.error('Error fetching suggested organizations:', error);
      setIsMemberLoading(false);
    }
  };
  const fetchReport = async () => {
    try {
      if (userId !== null) {
        setLoading(true);
        const response = await organizationAPI.getUserReport(userId);
        setCourses(response.courses);
        setTasks(response.tasks);
        setContests(response.contests);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching user report:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userId !== null) {
      fetchReport();
    }
  }, [userId]);

  useEffect(() => {
    fetchOrganizationMembers();
  }, []);

  return isMemberLoading ? (
    <Skeletons type="CircularLoader" />
  ) : (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        p: '32px',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: { xs: '18px', md: '24px' },
          fontWeight: '500',
          marginBottom: '8px',
          color: 'rgba(2, 19, 41, 1)',
        }}
      >
        Member Report
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: { xs: '14px', md: '14px' },
          fontWeight: '400',
          marginBottom: '16px',
          color: 'rgba(120, 120, 120, 1)',
        }}
      >
        View Member's Reports
      </Typography>
      <Divider />
      {organizationMembers.length > 0 ? (
        <>
          <Stack direction={{ xs: 'column', md: 'column', lg: 'row' }} spacing={4} sx={{ mt: 2 }}>
            <Box sx={{ width: '100%', maxWidth: { xs: '100%', md: '490px' } }}>
              <InvitesMemberList
                organizationMembers={organizationMembers}
                showRole={false}
                showDeleteButton={false}
                fetchMembers={fetchOrganizationMembers}
                DataFromApiInvites={[]}
                setUserId={setUserId}
              />
            </Box>

            {userId ? (
              <Box sx={{ width: '100%' }}>
                {loading ? (
                  <Skeletons type="CircularLoader" />
                ) : (
                  <>
                    <Accordion
                      sx={{
                        boxShadow: 'none',
                        '&:before': {
                          display: 'none',
                        },
                        '& .MuiAccordion-root': {
                          border: 'none',
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          '&.MuiAccordionSummary-root': {
                            border: '1px solid rgba(236, 239, 253, 1)',
                            backgroundColor: 'rgba(249, 250, 253, 1)',
                            borderRadius: '8px 8px 0px 0px',
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '500',
                            color: 'rgba(62, 67, 75, 1)',
                          }}
                        >
                          Courses
                        </Typography>
                      </AccordionSummary>
                      <CourseAccordion courses={courses} />
                    </Accordion>
                    <Accordion
                      sx={{
                        boxShadow: 'none',
                        '&:before': {
                          display: 'none',
                        },
                        '& .MuiAccordion-root': {
                          border: 'none',
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          '&.MuiAccordionSummary-root': {
                            border: '1px solid rgba(236, 239, 253, 1)',
                            backgroundColor: 'rgba(249, 250, 253, 1)',
                            borderRadius: '8px 8px 0px 0px',
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '500',
                            color: 'rgba(62, 67, 75, 1)',
                          }}
                        >
                          Tasks
                        </Typography>
                      </AccordionSummary>
                      <TaskAccordion tasks={tasks} />
                    </Accordion>
                    <Accordion
                      sx={{
                        boxShadow: 'none',
                        '&:before': {
                          display: 'none',
                        },
                        '& .MuiAccordion-root': {
                          border: 'none',
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          '&.MuiAccordionSummary-root': {
                            border: '1px solid rgba(236, 239, 253, 1)',
                            backgroundColor: 'rgba(249, 250, 253, 1)',
                            borderRadius: '8px 8px 0px 0px',
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: '500',
                            color: 'rgba(62, 67, 75, 1)',
                          }}
                        >
                          Contests
                        </Typography>
                      </AccordionSummary>
                      <ContestAccordion contests={contests} />
                    </Accordion>
                  </>
                )}
              </Box>
            ) : (
              <NoData Text={'Please select a user to view the reports here'} />
            )}
          </Stack>
        </>
      ) : (
        <Box sx={{ mt: 2 }}>
          <NoData Text={'There are no members in your organization yet'} />
        </Box>
      )}
    </Box>
  );
}
