import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Courses/Home';
import { CourseRoutes } from '../Routes/CourseRouters';
import { TaskRoutes } from '../Routes/TaskRoute';
import OrganizationSetting from '../pages/Organization/OrganizationSetting';
import { ContestRoutes } from '../Routes/ContestRoutes';
import CheckOut from '../pages/Organization/PlansCheckOut';
import OrgPlans from '../pages/Organization/Plans';
import { AdminRoutes } from './AdminRoutes';
import { ProblemRoutes } from './ProblemRoutes';
import io from 'socket.io-client';
import GetValidatedTokenData from '../utils/helper';
import ChatRoutes from './ChatRoutes';
import MemberReport from '../pages/Organization/MemberReport';
import axios from 'axios';
import { DocManagementRoutes } from './DocManagementRoutes';

export const OrganizationRoutes = ({ setorgImageStatus, orgImageStatus }) => {
  const currentUserInfo = GetValidatedTokenData();
  const [currentIp, setCurrentIp] = useState();
  const [socket, setSocket] = useState();

  const getData = async () => {
    const res = await axios.get('https://api.ipify.org/?format=json');
    setCurrentIp(res.data.ip);
  };

  const getSocket = () => {
    if (!socket) {
      const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
        query: {
          userId: currentUserInfo.id,
          clientIp: currentIp,
        },
        extraHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
        },
      });
      socket?.on('connect', () => {
        setSocket(socket);
        console.log('WebSocket connected');
      });
    }
  };

  useEffect(() => {
    getData();
    getSocket();
  }, []);

  return (
    <Routes>
      <Route path="problem/*" element={<ProblemRoutes />} />
      <Route path="admin/*" element={<AdminRoutes />} />
      <Route path="/dashboard" element={<Home />} />
      <Route
        path="/settings"
        element={
          <OrganizationSetting
            orgImageStatus={orgImageStatus}
            setorgImageStatus={setorgImageStatus}
          />
        }
      />
      <Route path="course/*" element={<CourseRoutes />} />
      <Route path="doc/*" element={<DocManagementRoutes />} />
      <Route path="task/*" element={<TaskRoutes />} />
      <Route path="contest/*" element={<ContestRoutes />} />
      <Route path="/checkout/:planId" element={<CheckOut />} />
      <Route path="/plans" element={<OrgPlans />} />
      <Route path="/chat/*" element={<ChatRoutes socket={socket} />} />
      <Route path="/member/report" element={<MemberReport />} />
    </Routes>
  );
};
