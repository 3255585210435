import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function PlanCard({ data, BgColor, ButtonText, isCurrent, orgName, showValidity }) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (isCurrent) {
      navigate(`/org/${orgName}/plans`);
    } else {
      localStorage.setItem('planToBuy', JSON.stringify(data));
      navigate(`/org/${orgName}/checkout/${data.id}`);
    }
  };

  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const validUpToDate = showValidity ? formatDate(data.validUpTo) : null;

  return (
    <Box
      sx={{
        border: '1px solid rgba(105, 138, 255, 1)',
        backgroundColor: BgColor,
        p: '16px',
        borderRadius: '4px',
        minWidth: '320px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { md: '20px', xs: '18px' },
              fontWeight: '500',
              color: BgColor === 'rgba(105, 138, 255, 1)' ? 'white' : 'rgba(2, 17, 72, 0.9)',
            }}
          >
            {data.name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: { md: '24px', xs: '22px' },
                fontWeight: '600',
                color: BgColor === 'rgba(105, 138, 255, 1)' ? 'white' : 'rgba(2, 17, 72, 1)',
              }}
            >
              ₹{data.price}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: { md: '14px', xs: '12px' },
                fontWeight: '300',
                color: BgColor === 'rgba(105, 138, 255, 1)' ? 'white' : 'rgba(120, 120, 120, 1)',
              }}
            >
              /Month
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: { md: '14px', xs: '12px' },
            fontWeight: '400',
            color: BgColor === 'rgba(105, 138, 255, 1)' ? 'white' : 'rgba(120, 120, 120, 1)',
            mb: 2,
            mt: 1,
          }}
        >
          Storage: {data.storage} GB
          {showValidity && validUpToDate !== null && ` / Valid upto: ${validUpToDate}`}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="outlined"
          onClick={handleButtonClick}
          sx={{
            background: 'white',
            borderColor: BgColor === 'rgba(105, 138, 255, 1)' ? 'white' : 'rgba(105, 138, 255, 1)',
            '&:hover': {
              background: 'white',
            },
          }}
        >
          {ButtonText}
        </Button>
      </Box>
    </Box>
  );
}
