import React, { useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Button, Box, Typography, Grid, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoadingButton from '@mui/lab/LoadingButton';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'linear-gradient(50.08deg, #002F7A -16.9%, #254FE0 -16.89%, #698AFF 87.11%)',
  },
}));

const SizeBox = styled(Box)(({ theme, selected }) => ({
  padding: '12px 24px',
  border: '1px solid rgba(235, 235, 235, 1)',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  width: 'fit-content',
  whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  ...(selected && {
    backgroundColor: 'rgba(105, 138, 255, 1)',
    color: 'white',
    boxShadow: `
        0px 0.33px 0.59px 0px rgba(105, 138, 255, 0.14),
        0px 1.15px 2.53px 0px rgba(105, 138, 255, 0.1),
        0px 2.73px 6.64px 0px rgba(105, 138, 255, 0.08),
        0px 5.68px 14.67px 0px rgba(105, 138, 255, 0.07),
        0px 11.59px 31.47px 0px rgba(105, 138, 255, 0.06),
        0px 28px 80px 0px rgba(105, 138, 255, 0.04)
      `,
  }),
}));
export default function HearAboutUs({ setStep }) {
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [otherSpecification, setOtherSpecification] = useState('');
  const [loading, setLoading] = useState(false);

  const solution = [
    'LinkedIn',
    'Podcast / Radio',
    'Search Engine (Google, Bing, etc.)',
    'Friend / Colleague',
    'Facebook / Instagram',

    'Other',
  ];

  const handleSolutionSelect = (solution) => {
    setSelectedSolution(solution);
    if (solution !== 'Other') {
      setOtherSpecification('');
    }
  };

  const handleOtherSpecification = (event) => {
    setOtherSpecification(event.target.value);
  };

  const onClickContinue = () => {
    setLoading(true);
    const solution = selectedSolution || otherSpecification;
    if (solution && solution.trim() !== '') {
      localStorage.setItem('HearAboutUs', solution);
    }
    setStep(6);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '32px',
            fontWeight: 500,
            color: 'rgba(2, 17, 72, 0.9)',
            mb: '40px',
          }}
        >
          How did you hear about us?
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ mb: 4, width: { md: '90%', lg: '65%', xs: '90%' } }}>
        {solution.map((solution) => (
          <Grid item key={solution}>
            <SizeBox
              selected={selectedSolution === solution}
              onClick={() => handleSolutionSelect(solution)}
            >
              {solution}
            </SizeBox>
          </Grid>
        ))}
      </Grid>
      {selectedSolution === 'Other' && (
        <TextField
          id="outlined-basic"
          label="Please Specify"
          variant="outlined"
          value={otherSpecification}
          onChange={handleOtherSpecification}
          sx={{ width: { md: '90%', lg: '65%', xs: '90%' } }}
        />
      )}
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 4,
        }}
      >
        <BorderLinearProgress variant="determinate" value={62.5} />
      </Box>
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 5,
          mb: 2,
        }}
      >
        {/* <Button variant="outlined" startIcon={<ArrowBackIosIcon />}>
          Back
        </Button> */}
        <LoadingButton
          variant="contained"
          onClick={() => {
            onClickContinue();
          }}
          loading={loading}
        >
          Continue
        </LoadingButton>
      </Box>
    </>
  );
}
