import React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Box } from '@mui/material';

export default function ContestAccordion({ contests }) {
  return (
    <AccordionDetails
      sx={{
        border: '1px solid rgba(236, 239, 253, 1)',
        borderTop: 'none',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        backgroundColor: 'white',
        p: 0,
        maxHeight: '500px',
        overflowY: 'auto',
      }}
    >
      {contests.length > 0 ? (
        contests.map((data, key) => (
          <React.Fragment key={key}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                borderBottom: '1px solid rgba(236, 239, 253, 1)',
                p: 1,
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: 1,
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'rgba(2, 17, 72, 0.9)',
                  }}
                >
                  {data.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'rgba(62, 67, 75, 0.7)',
                  }}
                >
                  {data.code}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '20px',
                    fontWeight: '500',
                    color: 'rgba(2, 17, 72, 0.9)',
                  }}
                >
                  {data.score}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'rgba(2, 17, 72, 0.9)',
                  }}
                >
                  Score
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                maxHeight: '250px',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#354150 #e4efef',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#e4efef',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#354150',
                  borderRadius: '2px',
                },
              }}
            >
              {data.problems?.map((problem, problemKey) => (
                <Box
                  key={problemKey}
                  sx={{
                    display: 'flex',
                    gap: 1,
                    borderBottom: '1px solid rgba(236, 239, 253, 1)',
                    p: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      width: '32px',
                      height: '32px',
                      padding: '4px',
                      borderRadius: '4px',
                      backgroundColor: 'rgba(242, 242, 242, 1)',
                      color: 'black',
                      fontSize: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: '300',
                      fontFamily: 'Poppins',
                      mx: 1,
                    }}
                  >
                    #
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: '500',
                        color: 'rgba(2, 17, 72, 0.9)',
                      }}
                    >
                      {problem.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: '500',
                        color: 'rgba(62, 67, 75, 0.7)',
                      }}
                    >
                      {problem.code}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '20px',
                        fontWeight: '500',
                        color: 'rgba(2, 17, 72, 0.9)',
                      }}
                    >
                      {problem.submission.score}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: '500',
                        color: 'rgba(2, 17, 72, 0.9)',
                      }}
                    >
                      Score
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </React.Fragment>
        ))
      ) : (
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '500',
            color: 'rgba(62, 67, 75, 0.7)',
            p: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          No attempted contests
        </Typography>
      )}
    </AccordionDetails>
  );
}
