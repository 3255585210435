const courseData = [
  {
    id: 1,
    title: 'Delectus aut autem',
    author: 'Chinua Achebe',
    duration: '30:23',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 2,
    title: 'Quis ut nam facilis',
    author: 'Hans Christian Andersen',
    duration: '17:45',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 3,
    title: 'Fugiat veniam minus',
    author: 'Dante Alighieri',
    duration: '10:23',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 4,
    title: 'Et porro tempora',
    author: 'Jane Austen',
    duration: '2:03:00',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 5,
    title: 'Laboriosam mollitia et',
    author: 'Paul Celan',
    duration: '30:05',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 6,
    title: 'Qui ullam ratione',
    author: 'Albert Camus',
    duration: '18:35',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 7,
    title: 'Illo expedita co',
    author: 'Samuel Beckett',
    duration: '1:00:03',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 8,
    title: 'Quo adipisci',
    author: 'Honor de Balzac',
    duration: '50:01',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 9,
    title: 'Molestiae perspiciatis',
    author: 'Giovanni Boccaccio',
    duration: '2:00:01',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
  {
    id: 10,
    title: 'Illo est ration',
    author: 'Jorge Luis Borges',
    duration: '1:00:03',
    sections: [
      'Section 1',
      'Section 2',
      'Section 3',
      'Section 4',
      'Section 5',
      'Section 6',
      'Section 7',
    ],
  },
];

export default courseData;
