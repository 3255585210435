import React, { useState } from 'react';
import InfoIcon from '../../../../assets/LandingPage/Org/MemberListInfoIcon.svg';
import { handleAlert } from '../../../../utils/handleAlert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { organizationAPI } from '../../../../api/requests/organization/organizationAPI';
import {
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Modal,
  Stack,
  Typography,
  Button,
  Box,
} from '@mui/material';
import '../OrganizationInvites.css';

export default function InvitesMemberListDetails({
  member,
  userRole,
  fetchMembers,
  showRole,
  showDeleteButton,
  setUserId = () => {},
}) {
  const [updatedRoles, setUpdatedRoles] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleRoleChange = async (username, selectedRole) => {
    try {
      if (member.find((u) => u.username === username).role !== selectedRole) {
        await organizationAPI.updateRole(username, selectedRole);
        setUpdatedRoles((prevRoles) => ({
          ...prevRoles,
          [username]: selectedRole,
        }));
        handleAlert('Role updated successfully:', 'success');
      }
    } catch (error) {
      console.error('Error updating role:', error);
      handleAlert('Error updating role', 'error');
    }
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedUser) {
      try {
        await organizationAPI.removeMember(selectedUser.username);
        handleAlert('Member removed successfully:', 'success');
        fetchMembers();
      } catch (error) {
        console.error('Error deleting:', error);
        handleAlert('Error removing member', 'error');
      }
    }
    setOpenModal(false);
    setSelectedUser(null);
  };

  const handleCancelDelete = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  const handleUserClick = (user) => {
    try {
      setUserId(user.id);
      setSelectedUserId(user.id);
    } catch (err) {
      console.log(err);
    }
  };

  const renderProfilePhoto = (user) => {
    if (user.profilePhotoLink) {
      return <img style={{ width: '40px', height: '40px' }} src={user.profilePhotoLink} alt="" />;
    } else {
      const firstNameInitial = user.firstName?.charAt(0) || '';
      const lastNameInitial = user.lastName?.charAt(0) || '';

      return (
        <div className="initials-container">
          <span>{firstNameInitial}</span>
          <span>{lastNameInitial}</span>
        </div>
      );
    }
  };

  const getTooltipText = (role) => {
    if (role === 'SUPERADMIN' || role === 'CREATOR') {
      return (
        <div>
          <ul>
            <li>
              Edit and update organizational details, including mission statements and branding.
            </li>
            <li>Track and analyze performance of any task or course across the entire platform.</li>
            <li>
              Analyze usage statistics to understand user behavior and identify popular courses.
            </li>
            <li>Contribute to strategic planning by leveraging insights from usage statistics.</li>
            <li>
              Serve as the top-level administrator, overseeing both educational and organizational
              aspects.
            </li>
          </ul>
        </div>
      );
    } else if (role === 'ADMIN') {
      return (
        <div>
          <ul>
            <li>
              Create and manage courses, specifying subjects, topics, and learning objectives.
            </li>
            <li>Assign tasks and activities to users for an engaging learning experience.</li>
            <li>Monitor individual user progress within assigned tasks and courses.</li>
            <li>Provide user support related to course content and tasks.</li>
          </ul>
        </div>
      );
    } else {
      return (
        <div>
          <ul>
            <li>Enroll in courses based on personal or organizational learning goals.</li>
            <li>Access course content, participate in activities, and complete assigned tasks.</li>
            <li>Track personal progress within enrolled courses.</li>
            <li>Seek assistance or clarification on course-related matters when needed.</li>
            <li>
              Contribute to the organization's goals by completing assigned tasks and courses.
            </li>
          </ul>
        </div>
      );
    }
  };

  const menuItemStyle = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    borderBottom: '1px solid rgba(2, 17, 72, 0.1)',
    '&:last-child': {
      borderBottom: 'none',
    },
  };

  const selectStyle = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.01em',
    textAlign: 'left',
    color: 'rgba(2, 17, 72, 0.8)',
    '&:before': { borderBottom: 'none' },
    '&:after': { borderBottom: 'none' },
    '& .MuiSelect-select': {
      paddingY: '10px',
      paddingRight: '30px !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      color: 'rgba(2, 17, 72, 0.8)',
      right: '0px',
    },
  };

  return (
    <div>
      {member.map((user) => (
        <Box
          key={user.username}
          onClick={() => handleUserClick(user)}
          sx={{
            cursor: 'pointer',
          }}
        >
          <div>
            <div
              className="viewSecondRow-orgDetailsMemberBlock-Email"
              style={{
                backgroundColor:
                  selectedUserId === user.id ? 'rgba(241, 244, 255, 1)' : 'transparent',
                borderBottom:
                  selectedUserId === user.id
                    ? '1px solid rgba(105, 138, 255, 1)'
                    : '1px solid rgba(225, 225, 225, 1)',
              }}
            >
              <div className="viewSecondRow-orgDetailsMemberBlockDetsCont">
                <div className="viewSecondRow-orgDetailsMemberBlockDetsCont-Email">
                  {renderProfilePhoto(user)}
                  <div className="viewSecondRow-orgDetailsMemberBlockDets viewSecondRow-orgDetailsMemberBlockDets-Invite">
                    <p className="memberTitle">
                      {user.firstName} {user.lastName}
                    </p>
                    <span>{user.username}</span>
                  </div>
                </div>
                {showRole && (
                  <>
                    {userRole === 'ADMIN' || userRole === null ? (
                      <div className="MembersList-ButtonsCont">
                        {user.role === null ? (
                          <div className="MembersList-Role">User</div>
                        ) : (
                          <div className="MembersList-Role">{user.role}</div>
                        )}
                        <Tooltip title={getTooltipText(user.role)} placement="left">
                          <img src={InfoIcon} alt="" />
                        </Tooltip>
                      </div>
                    ) : userRole === 'SUPERADMIN' || userRole === 'CREATOR' ? (
                      <div className="MembersList-ButtonsCont">
                        <FormControl fullWidth sx={{ border: 'none' }}>
                          <Select
                            value={updatedRoles[user.username] || user.role || 'USER'}
                            onChange={(e) => handleRoleChange(user.username, e.target.value)}
                            sx={selectStyle}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  backgroundColor: 'white',
                                  '& .MuiMenuItem-root': {
                                    color: 'rgba(2, 17, 72, 0.8)',
                                  },
                                },
                              },
                            }}
                          >
                            <MenuItem value="USER" sx={menuItemStyle}>
                              User
                            </MenuItem>
                            <MenuItem value="ADMIN" sx={menuItemStyle}>
                              Admin
                            </MenuItem>
                            <MenuItem value="SUPERADMIN" sx={menuItemStyle}>
                              Super Admin
                            </MenuItem>
                            {user.role === 'CREATOR' && (
                              <MenuItem value="CREATOR" sx={menuItemStyle} disabled>
                                Creator
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>

                        {user.role !== 'CREATOR' && showDeleteButton && (
                          <Tooltip title="Remove">
                            <IconButton sx={{ p: 0 }} onClick={() => handleDeleteClick(user)}>
                              <DeleteOutlineIcon sx={{ color: 'rgba(135, 135, 135, 1)' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </Box>
      ))}
      <Modal open={openModal} onClose={handleCancelDelete}>
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { sm: '400px' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            gap: '16px',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ mb: 3 }}>
            Are you sure you want to remove {selectedUser?.firstName} {selectedUser?.lastName} from
            the Organization?
          </Typography>
          <Stack direction="row" width="100%" gap="8px">
            <Button
              variant="outlined"
              fullWidth
              sx={{
                fontSize: { xs: '14px', sm: '16px' },
                textTransform: 'none',
              }}
              onClick={handleCancelDelete}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: { xs: '14px', sm: '16px' },
                textTransform: 'none',
                backgroundColor: '#93A9FA',
                '&:hover': { backgroundColor: '#93A9FA' },
              }}
              fullWidth
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
