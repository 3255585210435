const contestData = [
  {
    id: 1,
    title: 'Delectus aut autem',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '1:06:23',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 2,
    title: 'Quis ut nam fac',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '1:34:01',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 3,
    title: 'Fugiat veniam minus',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '55:32',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 4,
    title: 'Et porro tempora',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '2:03:00',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 5,
    title: 'Laboriosam mollitia',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '1:30:02',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 6,
    title: 'Qui ulla',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '2:00:01',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 7,
    title: 'Illo expedita',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '1:00:03',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 8,
    title: 'Quo adipisci enim',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '50:01',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 9,
    title: 'Molestiae perspiciatis',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '2:00:01',
    score: ['100', '200', '300', '400', '500'],
  },
  {
    id: 10,
    title: 'Illo est',
    type: 'TYPE',
    code: 'CODE',
    startsAt: '12:00 AM',
    duration: '1:00:03',
    score: ['100', '200', '300', '400', '500'],
  },
];

export default contestData;
