import React, { useState } from 'react';
import { Modal, Stack, Typography, TextField, Button } from '@mui/material';

export default function DeleteOrganizationModal({ open, onClose, organizationName, onDelete }) {
  const [orgNameConfirmation, setOrgNameConfirmation] = useState('');

  const handleDelete = () => {
    if (orgNameConfirmation === organizationName) {
      onDelete();
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOrgNameConfirmation('');
        onClose();
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '400px' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          gap: 2,
          borderRadius: '12px',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
          Delete this Organization
        </Typography>
        <Typography>
          Once you delete an organization, there is no going back. Please be certain.
        </Typography>
        <TextField
          helperText={`Enter "${organizationName}" to delete the organization`}
          FormHelperTextProps={{ sx: { color: '#FF3E3E' } }}
          value={orgNameConfirmation}
          onChange={(e) => setOrgNameConfirmation(e.target.value)}
        />
        <Stack direction="row" width="100%" gap={2}>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              fontSize: { xs: '14px', sm: '16px' },
              textTransform: 'none',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize: { xs: '14px', sm: '16px' },
              textTransform: 'none',
            }}
            fullWidth
            disabled={orgNameConfirmation !== organizationName}
            onClick={handleDelete}
            color="error"
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
