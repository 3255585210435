import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';

const ManualAvatar = ({ chat, bgColor, width, height }) => {
  const initials = (() => {
    if (chat?.name) {
      return chat.name.charAt(0);
    }
    if (chat?.username) {
      return chat.username.charAt(0);
    }
    if (chat?.groupChatData && chat?.groupChatData?.type === 'GROUP') {
      return chat.groupChatData.name ? chat.groupChatData.name.charAt(0) : '';
    }
    if (chat?.chatData && chat?.chatData?.username) {
      return chat.chatData.username.charAt(0);
    }
    if (chat?.senderData) {
      return chat.senderData.username.charAt(0);
    }
    return '';
  })();
  return (
    <MuiAvatar
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: width || '40px',
        height: height || '40px',
        marginRight: '10px',
        backgroundColor: bgColor,
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '19px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        borderRadius: '50%',
        textAlign: 'center',
        textTransform: 'uppercase',
      }}
    >
      {initials || ' '}
    </MuiAvatar>
  );
};

export default ManualAvatar;
