import React, { useRef } from 'react';
import './Careers.css';
import Footer from '../../components/Header/Footer';
import Navbar from '../../components/Header/Navbar';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
import careersImg from '../../assets/LandingPage/Hero/careersFirstImg.png';
import noOpeningsImg from '../../assets/LandingPage/icons/there-are-no-opening.svg';
import FlexibleWorkingIcon from '../../assets/LandingPage/icons/Flexible-Working-Hours.svg';
import RemoteIcon from '../../assets/LandingPage/icons/work-from-home.png';
import Career from '../../assets/LandingPage/icons/Flexible-career-path-icon.svg';
import Rewards from '../../assets/LandingPage/icons/Rewards-icon.svg';
import fitness from '../../assets/LandingPage/icons/fitness-icon.svg';
import education from '../../assets/LandingPage/icons/education-icon.svg';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const benefits = [
  {
    icon: FlexibleWorkingIcon,
    title: 'Flexible Working Hours',
    description: 'Choose your working hours to fit your lifestyle and personal needs.',
  },
  {
    icon: RemoteIcon,
    title: 'Remote Work',
    description: 'Work from anywhere with our fully remote work options.',
  },
  {
    icon: Career,
    title: 'Flexible Career Path',
    description: 'Tailor your career journey with opportunities for growth and advancement.',
  },
  {
    icon: Rewards,
    title: 'Employee Rewards and Bonuses',
    description: 'Receive recognition and bonuses for your hard work and contributions.',
  },
  {
    icon: fitness,
    title: 'Wellness Programs',
    description: 'Participate in wellness workshops, mental health support, and gym memberships.',
  },
  {
    icon: education,
    title: 'Professional Development',
    description: 'Access courses, workshops, and conferences to enhance your skills.',
  },
];

export default function Careers() {
  const jobsSectionRef = useRef(null);

  const scrollToJobs = () => {
    if (jobsSectionRef.current) {
      jobsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useDocumentTitle('Careers');

  return (
    <>
      <Navbar />
      <div className="careers-container">
        <div className="careers-left-side">
          <h2 className="careers-heading-hero secondary-text-color">
            Join Our Team and <br />
            <span className="primary">Shape the Future</span>
          </h2>
          <h6 className="sub-description mt-16">
            At Optigrit, we're always looking for talented individuals who are passionate about
            innovation and creativity. Join our team and be part of a dynamic environment where your
            skills and ideas can make a real impact. Together, we'll drive success and create
            solutions that empower people worldwide. Explore our opportunities and take the next
            step in your career with Optigrit.
          </h6>
          <button class="cta">
            <span class="hover-underline-animation" onClick={scrollToJobs}>
              {' '}
              See open postions{' '}
            </span>
            <svg
              id="arrow-horizontal"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="10"
              viewBox="0 0 46 16"
            >
              <path
                id="Path_10"
                data-name="Path 10"
                d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                transform="translate(30)"
              ></path>
            </svg>
          </button>
        </div>
        <div className="careers-right-side">
          <img src={careersImg} alt="" className="careers-img" />
        </div>
      </div>
      <div className="careers-benefit-container bg-secondary-color">
        <h3 className="careers-heading secondary-text-color">
          Why You'll <span className=" primary">Love Working with Us </span>
        </h3>
        <div className="career-benefit-items">
          {benefits.map((benefit, index) => (
            <div key={index} className="career-benefit-item">
              <img src={benefit.icon} alt="" className="career-benefit-icon" />
              <div className="career-benefits-content">
                <h6 className="career-benefit-title">{benefit.title}</h6>
                <p className="career-benefit-description">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div class="careers-jobs-container" ref={jobsSectionRef}>
        <h2 class="careers-heading secondary-text-color mb-24">Explore Job Opportunities</h2>
        <div class="no-openings-container">
          <div class="no-opening-content bg-secondary-color ">
            <div class="no-opening-text">
              <p class="sub-heading">Sorry, there are no openings available now</p>
              <p className="cvEmailText">
                Got other ideas? Send us your CV via
                <a href="mailto:support@optigrit.com" className="mailHereLink">
                  email{' '}
                  <ArrowOutwardIcon
                    sx={{
                      color: '#698aff',
                    }}
                    fontSize="small"
                  />
                </a>
              </p>
            </div>

            <img src={noOpeningsImg} alt="" class="no-opening-img" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
