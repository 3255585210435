import React, { useState, useEffect } from 'react';
import { Stack, Tab, Typography, Box, Tabs } from '@mui/material';
import BasicInfo from '../../components/Organization/BasicInfo';
import PropTypes from 'prop-types';
import OrganizationInvite from '../../components/LandingPage/Org/OrganizationDetails/OrganizationInvite';
import OrganizationConsumption from '../../components/LandingPage/Org/OrganizationDetails/OrganizationConsumption';
import OrganizationTransactions from '../../components/LandingPage/Org/OrganizationDetails/OrganizationTransactions';
import getRoleForOrganization from '../../utils/GetUserRoleInOrganization';
import Divider from '@mui/material/Divider';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function OrganizationSetting() {
  const [value, setValue] = useState(0);
  const [userRole, setUserRole] = useState('');

  const drawerWidth = 200;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabArray = [
    {
      value: 0,
      label: 'Basic Info',
      roles: ['CREATOR', 'SUPERADMIN'],
    },
    {
      value: 1,
      label: 'Invites',
      roles: ['CREATOR', 'SUPERADMIN'],
    },
    {
      value: 2,
      label: 'Consumption',
      roles: ['CREATOR', 'SUPERADMIN'],
    },
    {
      value: 3,
      label: 'Subscription',
      roles: ['CREATOR'],
    },
  ];
  const getVisibleTabs = (userRole) => {
    return TabArray.filter((tab) => tab.roles.includes(userRole));
  };

  useEffect(() => {
    const getRoleData = async () => {
      const role = await getRoleForOrganization();
      setUserRole(role);
      const visibleTabs = getVisibleTabs(role);
      if (visibleTabs.length > 0) {
        setValue(visibleTabs[0].value);
      }
    };
    getRoleData();
  }, []);

  return (
    <Stack
      sx={{
        flexGrow: 1,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        p: '32px',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: { xs: '18px', md: '24px' },
          fontWeight: '500',
          marginBottom: '8px',
          color: 'rgba(2, 19, 41, 1)',
        }}
      >
        Settings
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: { xs: '18px', md: '14px' },
          fontWeight: '400',
          marginBottom: '16px',
          color: 'rgba(120, 120, 120, 1)',
        }}
      >
        Manage your organization settings
      </Typography>
      <Divider />
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderColor: 'divider',
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '15px',
          }}
        >
          <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                '& .MuiTabs-scroller': {
                  overflowX: 'auto !important',
                },
              }}
            >
              {getVisibleTabs(userRole).map((tab) => (
                <Tab key={tab.value} label={tab.label} {...a11yProps(tab.value)} />
              ))}
            </Tabs>
          </Box>
        </Box>
        {getVisibleTabs(userRole).map((tab) => (
          <CustomTabPanel key={tab.value} value={value} index={tab.value}>
            {tab.value === 0 && <BasicInfo userRole={userRole} />}
            {tab.value === 1 && <OrganizationInvite sedForSettings={true} userRole={userRole} />}
            {tab.value === 2 && <OrganizationConsumption />}
            {tab.value === 3 && <OrganizationTransactions />}
          </CustomTabPanel>
        ))}
      </Box>
    </Stack>
  );
}
