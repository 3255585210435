import React, { useEffect, useState } from 'react';
import './OrgPlans.css';
import { useNavigate } from 'react-router-dom';
import { plansAPI } from '../../api/requests/plans';
import BigLoader from '../../components/Skeleton/BigLoader';
import { handleAlert } from '../../utils/handleAlert';
import { getOrgName } from '../../utils/appendOrgQuery';
import Tick_icon from '../../assets/LandingPage/Plans/Tick_icon.svg';
import Tick_icon_white from '../../assets/LandingPage/Plans/Tick_icon_white.svg';
import cross_icon from '../../assets/LandingPage/Plans/cross_icon.svg';

const OrgPlans = () => {
  const orgName = getOrgName();
  const navigate = useNavigate();

  const [currPlanPrice, setCurrPlanPrice] = useState(null);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    plansAPI
      .getPlans()
      .then((response) => {
        setData(response);
        setCurrPlanPrice(response?.currentPlan[0]?.price);
        localStorage.setItem('currentPlan', JSON.stringify(response?.currentPlan[0]));
        setLoading(false);
      })
      .catch((err) => {
        handleAlert('Something went wrong! please try again later', 'error');
        setLoading(false);
      });
  }, []);

  return loading ? (
    <BigLoader />
  ) : (
    <div className="planSection">
      <div>
        <h2 className="planHead">Find the Right Plan</h2>
        <h5 className="planSubhead">Choose a plan that’s right for you</h5>
        <div className="planDiv">
          {data?.currentPlan?.map((plan, key) => {
            return (
              <div className="planCardCurr">
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <h1 className="planCardHead">{plan.name}</h1>
                  <span className="activeNote">● Active</span>
                </div>
                <span className="planCardSubhead">{plan.description}</span>
                <div className="planCardPrice">
                  ₹{plan.price}
                  {plan.price !== 'Free' && <span className="planCardPer">/Month</span>}
                </div>
                {plan.name !== 'Basic Plan' && (
                  <div className="planValidUpto">
                    Valid Upto:{' '}
                    {plan?.validUpTo === 0
                      ? 'Lifetime'
                      : new Date(parseInt(plan?.validUpTo) * 1000).toDateString().slice(4)}
                  </div>
                )}

                <button
                  className={
                    plan.name !== 'Basic Plan'
                      ? 'AvailableGetStartedBtn'
                      : 'AvailableGetStartedBtnDisabled'
                  }
                  onClick={(e) => {
                    if (plan.name !== 'Basic Plan') {
                      localStorage.setItem('planToBuy', JSON.stringify(plan));
                      navigate(`/org/${orgName}/checkout/${plan.id}`);
                    }
                  }}
                >
                  Extend Now
                </button>
                <ul>
                  {JSON.parse(plan.data).map((feature, key) => {
                    const shouldUseTick =
                      plan.name === 'Basic Plan' &&
                      (feature === 'No limit on no of tasks' ||
                        feature === '15 GB of cumulative space available to create courses');

                    return (
                      <li className="planCardList">
                        <img
                          src={
                            plan.name === 'Basic Plan'
                              ? shouldUseTick
                                ? Tick_icon
                                : cross_icon
                              : Tick_icon
                          }
                          alt=""
                          style={{
                            flexShrink: 0,
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <span>{feature}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
          {data?.availablePlans?.map((plan, key) => {
            return (
              <div className="AvailableplanCard">
                <h1 className="AvailableplanCardHead">{plan.name}</h1>
                <div className="AvailableplanCardSubhead">{plan.description}</div>

                <div className="AvailableplanCardPrice">
                  ₹{plan.price}
                  {plan.price !== 'Free' && <span className="AvailableplanCardPer">/Month</span>}
                </div>
                <button
                  className={
                    plan.name !== 'Basic Plan'
                      ? 'AvailableGetStartedBtn'
                      : 'AvailableGetStartedBtnDisabled'
                  }
                  onClick={(e) => {
                    if (plan.name !== 'Basic Plan') {
                      localStorage.setItem('planToBuy', JSON.stringify(plan));
                      navigate(`/org/${orgName}/checkout/${plan.id}`);
                    }
                  }}
                >
                  {currPlanPrice !== null && plan.price > currPlanPrice
                    ? 'Upgrade Now'
                    : 'Get Started Now'}
                </button>
                <ul>
                  {JSON.parse(plan.data).map((feature, key) => {
                    const shouldUseTick =
                      plan.name === 'Basic Plan' &&
                      (feature === 'No limit on no of tasks' ||
                        feature === '15 GB of cumulative space available to create courses');
                    return (
                      <li className="AvailableplanCardList">
                        <img
                          src={
                            plan.name === 'Basic Plan'
                              ? shouldUseTick
                                ? Tick_icon_white
                                : cross_icon
                              : Tick_icon_white
                          }
                          alt=""
                          style={{
                            flexShrink: 0,
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <span>{feature}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrgPlans;
