import { Box, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { organizationAPI } from '../../../../api/requests/organization/organizationAPI';
import MetricesCard from '../../../Card/MetricesCard';
import DataUsageCard from '../../../Card/DataUsageCard';

import CourseCard from '../../../Card/CourseCard';
import CourseDetails from '../../../Card/CourseDetails';
import Skeletons from '../../../../components/Skeleton/Skeletons';

export default function OrganizationConsumption() {
  const [consumptionPageData, setConsumptionPageData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [totalStorage, setTotalStorage] = useState('');
  const [consumedStorage, setConsumedStorage] = useState('');
  const [availableStorage, setAvailableStorage] = useState('');
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await organizationAPI.getConsumptionDetails();
      setConsumptionPageData(response);
      setConsumedStorage(response?.storageUsed?.consumedStorage || '0');
      setTotalStorage(response?.storageUsed?.aggregateStorage || '0');
      setAvailableStorage(response?.storageUsed?.availableStorage || '0');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const metrics = [
    {
      id: 1,
      title: 'Members',
      value: `${consumptionPageData?.metrics?.membersCount}`,
      color: 'rgba(105, 138, 255, 1)',
    },
    {
      id: 2,
      title: 'Tasks',
      value: `${consumptionPageData?.metrics?.tasksCount}`,
      color: 'rgba(255, 215, 0, 1)',
    },
    {
      id: 3,
      title: 'Courses',
      value: `${consumptionPageData?.metrics?.coursesCount}`,
      color: 'rgba(143, 188, 143, 1)',
    },
    {
      id: 4,
      title: 'Contests',
      value: `${consumptionPageData?.metrics?.contestsCount}`,
      color: 'rgba(255, 111, 97, 1)',
    },
  ];

  const coursesData = consumptionPageData?.courses?.map((course) => ({
    id: `${course?.id}`,
    profilephoto: `${course?.imageUrl || ''}`,
    courseName: `${course?.title}`,
    percentage: `${course?.PercentageConsumedOfWholeOrganization || '0'}`,
  }));

  const selectedCourse =
    consumptionPageData?.courses?.find((course) => course.id === selectedCourseId) ||
    consumptionPageData?.courses?.[0];

  const courseDetails = selectedCourse
    ? [
        { id: 1, title: 'Data Consumed', value: `${selectedCourse.consumedStorage}` },
        { id: 2, title: 'Enrolled Members', value: `${selectedCourse.enrolledUsers}` },
        { id: 3, title: 'Ratings Received', value: `${selectedCourse.ratings}` },
        { id: 4, title: 'Videos', value: `${selectedCourse.videosCount}` },
      ]
    : [];

  const selectedCourse2 = selectedCourse
    ? {
        courseTitle: `${selectedCourse.title}`,
        Percentage: `${selectedCourse.PercentageConsumedOfWholeOrganization || 0}`,
      }
    : {};

  return isLoading ? (
    <Skeletons type="CircularLoader" />
  ) : (
    <Stack
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        pt: '20px',
      }}
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row', lg: 'row' },
          gap: '32px',
          width: '100%',
          justifyContent: 'start',
        }}
      >
        <MetricesCard Data={metrics} Border={true} />
        <DataUsageCard
          TotalStorage={totalStorage}
          ConsumedStorage={consumedStorage}
          AvailableStorage={availableStorage}
        />
      </Stack>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fonSize: '18px',
            fontWeight: '500',
            color: 'rgba(62, 67, 75, 1)',
            my: '32px',
          }}
        >
          Course Analytics
        </Typography>
        {consumptionPageData?.courses?.length > 0 ? (
          <Stack
            sx={{
              flexDirection: { xs: 'column', lg: 'row' },
              display: 'flex',
              justifyContent: 'start',
              gap: '32px',
            }}
          >
            <CourseCard
              Data={coursesData}
              setSelectedCourseId={setSelectedCourseId}
              selectedCourseId={selectedCourseId}
              fetchData={fetchData}
            />
            <CourseDetails
              Heading={selectedCourse2.courseTitle}
              Data={courseDetails}
              Progress={selectedCourse2.Percentage}
            />
          </Stack>
        ) : (
          <Typography>No Courses Available now</Typography>
        )}
      </Box>
    </Stack>
  );
}
