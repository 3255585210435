import React, { useState, useRef, useEffect } from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Box, Button } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimelapseIcon from '@mui/icons-material/Timelapse';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function TaskAccordion({ tasks }) {
  const [expandedTask, setExpandedTask] = useState(null);
  const [showReadMore, setShowReadMore] = useState({});

  const descriptionRefs = useRef([]);

  useEffect(() => {
    tasks.forEach((_, index) => {
      if (descriptionRefs.current[index]) {
        const { scrollHeight, clientHeight } = descriptionRefs.current[index];
        setShowReadMore((prev) => ({
          ...prev,
          [index]: scrollHeight > clientHeight,
        }));
      }
    });
  }, [tasks]);

  const handleReadMore = (key) => {
    setExpandedTask(expandedTask === key ? null : key);
  };

  function formatDuration(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;

    let formattedDuration = '';

    if (days > 0) {
      formattedDuration += `${days} days, `;
    }
    if (hours > 0) {
      formattedDuration += `${hours} hours, `;
    }
    if (minutes > 0) {
      formattedDuration += `${minutes} minutes, `;
    }
    if (remainingSeconds > 0) {
      formattedDuration += `${remainingSeconds} seconds`;
    }

    return formattedDuration.trim().replace(/,\s*$/, '');
  }

  return (
    <AccordionDetails
      sx={{
        border: '1px solid rgba(236, 239, 253, 1)',
        borderTop: 'none',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        backgroundColor: 'white',
        p: 0,
        maxHeight: '500px',
        overflowY: 'auto',
      }}
    >
      {tasks.length > 0 ? (
        tasks.map((data, key) => (
          <React.Fragment key={key}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                borderBottom: '1px solid rgba(236, 239, 253, 1)',
                p: 1,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: 1,
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'rgba(2, 17, 72, 0.9)',
                  }}
                >
                  {data.title}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0,
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography
                    ref={(el) => (descriptionRefs.current[key] = el)}
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: '500',
                      color: 'rgba(62, 67, 75, 0.7)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: expandedTask === key ? 'none' : 2,
                      WebkitBoxOrient: 'vertical',
                      flexGrow: 1,
                    }}
                  >
                    Description: {data.description}
                  </Typography>
                  {showReadMore[key] && (
                    <Button
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontWeight: '500',
                        color: 'rgba(62, 67, 75, 0.7)',
                        textTransform: 'none',
                        padding: 0,
                        alignSelf: 'flex-start',
                      }}
                      onClick={() => handleReadMore(key)}
                    >
                      {expandedTask === key ? 'Show less' : 'Read more'}
                    </Button>
                  )}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { md: 'row', xs: 'column' },
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: '500',
                      color: 'rgba(62, 67, 75, 0.7)',
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    Starts At
                    <CalendarTodayIcon
                      sx={{
                        color: 'rgba(62, 67, 75, 0.7)',
                      }}
                    />
                    {new Date(parseInt(data?.startsAt) * 1000).toDateString().slice(4)}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: '500',
                      color: 'rgba(62, 67, 75, 0.7)',
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    Duration
                    <TimelapseIcon
                      sx={{
                        color: 'rgba(62, 67, 75, 0.7)',
                      }}
                    />
                    {formatDuration(data?.duration)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <BorderLinearProgress
                    variant="determinate"
                    value={data.progress}
                    sx={{
                      flexGrow: 1,
                      marginRight: '8px',
                      backgroundColor: 'white',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: 'rgba(171, 190, 255, 1)',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontWeight: '500',
                      color: 'rgba(62, 67, 75, 1)',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {data.progress}% Completed
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                maxHeight: '250px',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#354150 #e4efef',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#e4efef',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#354150',
                  borderRadius: '2px',
                },
              }}
            >
              {data.subtasks?.map((subtask, subkey) => (
                <Box
                  key={subkey}
                  sx={{
                    display: 'flex',
                    gap: 1,
                    borderBottom: '1px solid rgba(236, 239, 253, 1)',
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: '32px',
                      height: '32px',
                      padding: '4px',
                      borderRadius: '4px',
                      backgroundColor: 'rgba(242, 242, 242, 1)',
                      color: 'black',
                      fontSize: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: '300',
                      fontFamily: 'Poppins',
                      mx: 1,
                    }}
                  >
                    #
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: '500',
                        color: 'rgba(2, 17, 72, 0.9)',
                      }}
                    >
                      {subtask.title}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0,
                        flexWrap: 'wrap',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: 'rgba(62, 67, 75, 0.7)',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          flexGrow: 1,
                        }}
                      >
                        Description: {subtask.description}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: 'rgba(62, 67, 75, 0.7)',
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                        }}
                      >
                        Starts At
                        <CalendarTodayIcon
                          sx={{
                            color: 'rgba(62, 67, 75, 0.7)',
                          }}
                        />
                        {new Date(parseInt(subtask?.startsAt) * 1000).toDateString().slice(4)}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: 'rgba(62, 67, 75, 0.7)',
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                        }}
                      >
                        Duration
                        <TimelapseIcon
                          sx={{
                            color: 'rgba(62, 67, 75, 0.7)',
                          }}
                        />
                        {formatDuration(subtask?.duration)}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={subtask.progress}
                        sx={{
                          flexGrow: 1,
                          marginRight: '8px',
                          backgroundColor: 'white',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: 'rgba(171, 190, 255, 1)',
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontWeight: '500',
                          color: 'rgba(62, 67, 75, 1)',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {subtask.progress}% Completed
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </React.Fragment>
        ))
      ) : (
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '500',
            color: 'rgba(62, 67, 75, 0.7)',
            p: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          No assigned tasks
        </Typography>
      )}
    </AccordionDetails>
  );
}
