import React from 'react';
import '../OrganizationInvites.css';
import OrganizationMembers from '../../Org/OrganizationMembers/MembersList';
import { Typography, Box } from '@mui/material';

export default function SentReceivedInvites({ DataFromApiRequests, fetchRequests, fetchMembers }) {
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontSize: '18px',
          fontWeight: '500',
          letterSpacing: '0.01em',
          color: 'rgba(62, 67, 75, 1)',
          mt: 1,
          pb: 2.5,
          borderBottom: '1px solid rgba(225, 225, 225, 1)',
        }}
      >
        Requests Received
      </Typography>
      {DataFromApiRequests && DataFromApiRequests.length > 0 ? (
        <div className="SentReceivedInvites-1-MemberList custom-scrollbar">
          <OrganizationMembers
            members={DataFromApiRequests}
            showEmail={false}
            inviteAccepted={false}
            inviteRejected={false}
            inviteReceived={false}
            requestReceived={true}
            fetchRequests={fetchRequests}
            fetchMembers={fetchMembers}
          />
        </div>
      ) : (
        <div className="SentReceivedInvites-1-MemberList">
          <span className="DataFromApiReceived">No requests received</span>
        </div>
      )}
    </Box>
  );
}
