import { Box, Stack, Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';

export default function CourseDetails({ Heading, Data, Progress }) {
  const chartData = {
    labels: ['Consumed', 'Available'],
    datasets: [
      {
        data: Progress,
        backgroundColor: ['rgba(40, 81, 226, 1)', '#ECEFFD'],
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.parsed.toFixed(2)}%`;
          },
        },
      },
    },
    cutout: '90%',
  };
  return (
    <Stack
      sx={{
        borderRadius: '8px',
        width: { xs: '100%', lg: '45%' },
        border: '1px solid rgba(236, 239, 253, 1)',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: '500',
          color: 'rgba(62, 67, 75, 1)',
          backgroundColor: 'rgba(249, 250, 253, 1)',
          padding: '12px 20px',
        }}
      >
        {Heading}
      </Typography>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          flexDirection: { md: 'row', xs: 'column' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          {Data?.map((item, key) => (
            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '8px', mr: 3 }}>
                <Box
                  sx={{
                    width: '14px',
                    height: '14px',
                    backgroundColor: 'rgba(40, 81, 226, 1)',
                    borderRadius: '21px',
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'rgba(62, 67, 75, 0.8)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    flexGrow: 1,
                    minWidth: 0,
                  }}
                >
                  {item.title}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: '500',
                  color: 'rgba(2, 17, 72, 1)',
                }}
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            width: '100%',
            maxWidth: { lg: '150px', md: '150px', xs: '120px' },
            position: 'relative',
          }}
        >
          <Doughnut data={chartData} options={chartOptions} />
          <Typography
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontWeight: '500',
              fontSize: '28px',
              fontFamily: 'Poppins',
              color: 'rgba(159, 157, 186, 1)',
            }}
          >
            {Progress}%
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}
