import React, { useState } from 'react';
import { TextField, Box, InputAdornment, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';

export default function InviteWithCodeDetails({ Code }) {
  const [copied, setCopied] = useState(false);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(Code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        value={Code}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={copyCodeToClipboard}
                edge="end"
                sx={{ color: 'rgba(105, 138, 255, 1)' }}
              >
                {copied ? <DoneIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          backgroundColor: 'rgba(246, 248, 255, 1)',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid rgba(235, 235, 235, 1)',
            },
            '&:hover fieldset': {
              border: '1px solid rgba(235, 235, 235, 1)',
            },
            '&.Mui-focused fieldset': {
              border: '1px solid rgba(235, 235, 235, 1)',
            },
          },
          width: '100%',
        }}
        size="small"
      />
    </Box>
  );
}
