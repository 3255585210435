import React, { useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Button, Box, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import { handleAlert } from '../../utils/handleAlert';
import LoadingButton from '@mui/lab/LoadingButton';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'linear-gradient(50.08deg, #002F7A -16.9%, #254FE0 -16.89%, #698AFF 87.11%)',
  },
}));
export default function OganizationName({ setStep }) {
  const [orgName, setOrgName] = useState('');
  const [orgNameError, setOrgNameError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCreateOrg = async () => {
    if (orgName.trim() !== '') {
      setLoading(true);
      try {
        const data = {
          name: orgName,
        };

        organizationAPI
          .addOrganization(data)
          .then((res) => {
            if (res.code === 'FREE_ORGANIZATION_COUNT_LIMIT_EXCEEDED') {
              return;
            }
            localStorage.setItem('orgName', orgName);
            setStep(2);
            setLoading(false);
          })
          .catch(() => {
            handleAlert('Something went wrong! please try again later', 'error');
            setLoading(false);
          });
      } catch (error) {
        console.error('Error creating organization:', error);
        setLoading(false);
      }
    } else {
      setOrgNameError('* Organization name is mandatory');
    }
  };
  function validateName(e) {
    if (e.length === 0) {
      setOrgName(e);
      return;
    }
    setOrgNameError('');
    var re = new RegExp('^[a-zA-z_]+$');
    if (re.test(e)) {
      setOrgName(e);
    } else {
      setOrgNameError('Capital and small letters, along with underscores, are allowed');
    }
  }
  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '32px',
          fontWeight: 500,
          color: 'rgba(2, 17, 72, 0.9)',
          width: { md: '90%', lg: '65%', xs: '90%' },
          mb: '40px',
          mt: 1,
        }}
      >
        Please enter a name for your organization
      </Typography>
      <TextField
        id="outlined-basic"
        label="Enter organization name"
        variant="outlined"
        helperText={orgNameError}
        onChange={(e) => validateName(e.target.value)}
        sx={{ width: { md: '90%', lg: '65%', xs: '90%' } }}
      />
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          mt: 4,
        }}
      >
        <BorderLinearProgress variant="determinate" value={25} />
      </Box>
      <Box
        sx={{
          width: { md: '90%', lg: '65%', xs: '90%' },
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 5,
          mb: 2,
        }}
      >
        {/* <Button variant="outlined" startIcon={<ArrowBackIosIcon />}>
          Back
        </Button> */}
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() => {
            handleCreateOrg();
          }}
          loading={loading}
        >
          Continue
        </LoadingButton>
      </Box>
    </>
  );
}
