import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip);

export default function MetricesCard({ Data }) {
  const chartData = {
    labels: Data.map((item) => item.title),
    datasets: [
      {
        data: Data.map((item) => item.value),
        backgroundColor: Data.map((item) => item.color),
        hoverOffset: 4,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    cutout: '80%',
  };

  return (
    <Stack
      sx={{
        borderRadius: '8px',
        width: { lg: '35%', md: '50%', xs: '100%' },
        border: '1px solid rgba(236, 239, 253, 1)',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: '500',
          backgroundColor: 'rgba(249, 250, 253, 1)',
          padding: '12px 20px',
        }}
      >
        Matrices
      </Typography>
      <Box
        sx={{
          padding: '20px 16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {Data &&
            Data.map((item, key) => (
              <Stack
                key={key}
                sx={{
                  mb: { md: 1.7, xs: 0.5 },
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    mr: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: '14px',
                      height: '14px',
                      backgroundColor: item?.color,
                      borderRadius: '21px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '28px',
                      color: 'rgba(62, 67, 75, 0.8)',
                      ml: 1,
                    }}
                  >
                    {item?.title}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '28px',
                    color: 'rgba(2, 17, 72, 1)',
                  }}
                >
                  {item.value === undefined || item.value === null ? '0' : item?.value}
                </Typography>
              </Stack>
            ))}
        </Box>

        <Box
          sx={{
            width: '100%',
            maxWidth: { lg: '150px', md: '150px', xs: '120px' },
          }}
        >
          <Doughnut data={chartData} options={chartOptions} />
        </Box>
      </Box>
    </Stack>
  );
}
