import React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Box } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import CourseDefaultImg from '../../assets/CourseImages/ReportsPageCourseThumbnail.svg';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function CourseAccordion({ courses }) {
  const [imageVisible, setImageVisible] = useState(true);

  return (
    <AccordionDetails
      sx={{
        border: '1px solid rgba(236, 239, 253, 1)',
        borderTop: 'none',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        backgroundColor: 'white',
        p: 0,
        maxHeight: '500px',
        overflowY: 'auto',
      }}
    >
      {courses.length > 0 ? (
        courses.map((data, key) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              gap: 1,
              borderBottom: '1px solid rgba(236, 239, 253, 1)',
              p: 1,
            }}
          >
            {imageVisible && data.imageUrl != null ? (
              <img
                src={data.imageUrl}
                alt=""
                style={{
                  width: '80px',
                  height: '60px',
                  borderRadius: '4px',
                }}
                onError={() => setImageVisible(false)}
              />
            ) : (
              <img
                src={CourseDefaultImg}
                alt=""
                style={{
                  width: '80px',
                  height: '60px',
                  borderRadius: '4px',
                }}
                onError={() => setImageVisible(false)}
              />
            )}

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                  color: 'rgba(2, 17, 72, 0.9)',
                }}
              >
                {data.title}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <BorderLinearProgress
                  variant="determinate"
                  value={data.progress}
                  sx={{
                    flexGrow: 1,
                    marginRight: '8px',
                    backgroundColor: 'white',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'rgba(171, 190, 255, 1)',
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontWeight: '500',
                    color: 'rgba(62, 67, 75, 1)',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data.progress}% Watched
                </Typography>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '500',
            color: 'rgba(62, 67, 75, 0.7)',
            p: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          No enrolled courses
        </Typography>
      )}
    </AccordionDetails>
  );
}
