import React, { useState } from 'react';
import '../OrganizationInvites.css';
import InviteWithCodeDetails from './InviteWithCodeDetails';
import { Typography, Box, Button } from '@mui/material';
import InviteWithMailModal from './InviteWithMailModal';

export default function InviteWithCode({ code, fetchInvites }) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        gap: 3,
        pb: 3,
        borderBottom: '1px solid rgba(225, 225, 225, 1)',
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: '500',
            letterSpacing: '0.01em',
            color: 'rgba(62, 67, 75, 1)',
            mb: 2,
          }}
        >
          Invite members
        </Typography>
        <Button variant="contained" onClick={handleOpenModal}>
          Invite Member By Mail
        </Button>
      </Box>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: '500',
            letterSpacing: '0.01em',
            mb: 2,
            color: 'rgba(62, 67, 75, 1)',
          }}
        >
          Invite with Code
        </Typography>
        <InviteWithCodeDetails Code={code} />
      </Box>
      <InviteWithMailModal open={openModal} setOpen={setOpenModal} fetchInvites={fetchInvites} />
    </Box>
  );
}
